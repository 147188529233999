import React from 'react';
import { onlyCommas } from '../../../App/Utilities/Utilities';
const ProvisionsList = ({ provisions }) => {
  const mod = [
    {
      title: 'Հաշվարկվող նվազագույն աշխատավարձը (սոց.վճար վճարող)',
      value: onlyCommas(provisions?.minSalary1),
    },
    {
      title: 'Հաշվարկվող նվազագույն աշխատավարձը (սոց.վճար չվճարող)',
      value: onlyCommas(provisions?.minSalary2),
    },
    {
      title: 'Հնգօրյա շաբաթվա միջին օրերի քանակը',
      value: provisions?.leavDay5,
    },

    {
      title: 'Վեցօրյա շաբաթվա միջին օրերի քանակը',
      value: provisions?.leavDay6,
    },
    {
      title:
        'Հղիության, ծննդաբերության անաշխատունակության նպաստի միջին օրերի քանակը',
      value: provisions?.leavDay,
    },
  ];

  return (
    <div className="provision-box">
      <div className="provision-box-content">
        <h2 className="provisions-title">Դրույթներ</h2>
        {mod?.map((item, index) => (
          <div key={index} className="L-provisions-list">
            <h3>{item.title}</h3>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProvisionsList;
