import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, putRequest } from '../../Api/Api';
import pencil from '../../Assets/icons/pencil.svg';
import deleteIcon from '../../Assets/icons/deleteIcon.png';
import TopArrow from '../../Components/TopArrow/TopArrow';
import './style.scss';
import SelectUserPicture from '../SelectUserPicture/SelectUserPicture';
import ChangePassword from '../ChangePassword/ChangePassword';
import CurrentEmailFiled from '../../Elements/CurrentEmailFiled/CurrentEmailFiled';
import UserInfo from '../../Elements/UserInfo/UserInfo';
import defaultUserImg from '../../Assets/image/initUserP.png';
import Alert from '../../Components/Alert';
import Header from '../../Components/Header/Header';
import { authClear, updateUser } from '../../Redux/authSlices/useAction';
import CheckNewEmailMes from '../../Elements/CurrentEmailFiled/CheckNewEmailMes';
import { mainPage, windowReload } from '../../Utilities/Utilities';

const UserData = () => {
  const [isOpenUserImgModal, setIsOpenUserImgModal] = useState(false);
  const [isOpenUserDataModal, setIsOpenUserDataModal] = useState(false);
  const [isOpenUserEmailModal, setIsOpenUserEmailModal] = useState(false);
  const [isOpenUserPasModal, setIsOpenUserPasModal] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const { userID, firstName, lastName, phone, email, picture } = useSelector(
    (state) => state.auth,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorHandler, setErrorHandler] = useState('');
  const [img, setImg] = useState(picture);
  const getUserImg = () => {
    getRequest(`getPicture?UserID=${userID}`)
      .then((res) => {
        setImg(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };
  useEffect(() => {
    getUserImg();
  }, [userID]);

  const openUserImgModal = () => {
    setIsOpenUserImgModal(true);
  };
  const openUserDataModal = () => {
    setIsOpenUserDataModal(true);
  };
  const openUserEmailModal = () => {
    setIsOpenUserEmailModal(true);
  };
  const openUserPasModal = () => {
    setIsOpenUserPasModal(true);
  };
  const redirectHandler = () => {
    history.push(mainPage);
  };

  const clearUser = () => {
    localStorage.removeItem('persist:root');
    dispatch(authClear());

    history.push('/OneWindow');
    windowReload();
  };
  const onDeleteUserImg = () => {
    if (img?.length > 0) {
      putRequest(`deletePicture?userID=${userID}`).then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(
            updateUser({
              picture: null,
            }),
          );
          setImg(null);
        }
      });
    }
    return;
  };
  return (
    <>
      <Header
        headerBtn={false}
        pageTitle="Օգտվողի տվյալները"
        btnText="Օգտակար գործիքներ"
      />

      <TopArrow onClick={redirectHandler} customClass="TopArrow" />

      <div className="G-user-data-block">
        <div className="user-img user-data-block-solid ">
          <div className="user-img-block G-flex">
            <div className="user-img-block">
              <img
                src={`${
                  img ? `data:image/jpeg;base64,${img}` : defaultUserImg
                } `}
                alt=""
              />
            </div>
            <div className="delete-icon-box" onClick={onDeleteUserImg}>
              <img src={deleteIcon} alt="" />
            </div>
          </div>

          <span className="pencil-box" onClick={openUserImgModal}>
            <img src={pencil} alt="" />
          </span>
        </div>
        <div className="user-data user-data-block-solid ">
          <p className="G-title-p">Անձնական տվյալներ</p>
          <div className="user-info">
            <span>{firstName}</span>
            <span>{lastName}</span>
            <span>{phone}</span>
          </div>
          <span className="pencil-box" onClick={openUserDataModal}>
            <img src={pencil} alt="" />
          </span>
        </div>
        <div className="user-data user-data-block-solid ">
          <p className="G-title-p">Մուտքանուն </p>
          <div className="user-info">
            <span>{email}</span>
          </div>
          <span className="pencil-box" onClick={openUserEmailModal}>
            <img src={pencil} alt="" />
          </span>
        </div>
        <div className="user-data user-data-block-solid ">
          <p className="G-title-p">Գաղտնագիր </p>
          <div className="user-info">
            <span>**********</span>
          </div>
          <span className="pencil-box" onClick={openUserPasModal}>
            <img src={pencil} alt="" />
          </span>
        </div>
        {isOpenUserImgModal && (
          <SelectUserPicture
            closeModal={setIsOpenUserImgModal}
            img={img}
            getUserImg={getUserImg}
          />
        )}
        {isOpenUserDataModal && (
          <UserInfo closeModal={setIsOpenUserDataModal} />
        )}
        {isOpenUserPasModal && (
          <ChangePassword closeModal={setIsOpenUserPasModal} />
        )}
        {isOpenUserEmailModal && (
          <CurrentEmailFiled
            closeModal={setIsOpenUserEmailModal}
            setErrorHandler={setErrorHandler}
            setSentEmail={setSentEmail}
          />
        )}
        <Alert description={errorHandler} onClose={setErrorHandler} />
      </div>
      {sentEmail && (
        <CheckNewEmailMes
          onRemoveUserData={clearUser}
          closeModal={setSentEmail}
          currentEmail={sentEmail}
        />
      )}
    </>
  );
};

export default UserData;
