// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { getRequest } from "../Api/Api";

// export const useFetchCurrency = (url = null) => {
//   const { currentCompanyID } = useSelector((state) => state.auth);
//   const [getCurrencies, setGetCurrencies] = useState([]);
//   const mainUrl = `getCompanyCurrencies?companyID=${currentCompanyID}&takeCount=${100}`

//   useEffect(() => {
//     getRequest(url ? url : mainUrl)
//       .then((res) => {
//         setGetCurrencies(res.data);
//       })
//       .catch((err) => {
//         console.log("Err" + err);
//       });
//   }, [currentCompanyID, mainUrl, url]);

//   return [getCurrencies];
// };

import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../Api/Api';

const createApiEndpoint = (companyID) => {
  return `getCompanyCurrencies?companyID=${companyID}&takeCount=${100}`;
};

export const useFetchCurrency = (url = null) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [getCurrencies, setGetCurrencies] = useState([]);
  const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiEndpoint = useMemo(() => {
      return url || createApiEndpoint(currentCompanyID);
    }, [currentCompanyID, url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getRequest(apiEndpoint);
        setdata(res.data);
      } catch (err) {
        setError(`Error: ${err}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiEndpoint]);

  return { data, loading, error };
};
