import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import useIsMounted from '../../hooks/useIsMounted';
import PaymentListsBtn from './PaymentListsBtn';
import { dataTimeFormat, dateFormat } from '../../../App/Utilities/Utilities';
import DateComponent from '../../components/dateComponent/DateComponent';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import Input from '../../Input/Input';

const NewPaymentListForm = ({
  handleClose,
  getNewPaymentListForm,
  bankCode,
  newPaymentListForm,
  getNewPaymentList,
  setIsDisabledButtons,
}) => {
  const isMounted = useIsMounted();
  useEffect(() => {
    getNewPaymentListForm(bankCode);

    setIsDisabledButtons((prevState) => ({
      ...prevState,
      confirm: false,
    }));
  }, [bankCode]);

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...newPaymentListForm },
    //validationSchema: TablesValidation,
    onSubmit: async (values, { resetForm }) => {
      const params = {
        bankCode: values.bankCode,
        percent: values.percent,
        salaryDate: dataTimeFormat(values.salaryDate),
      };

      try {
        await getNewPaymentList(params, resetForm);
        if (isMounted) {
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    },
  });

  const buttonsConfig = [
    {
      text: 'Չեղարկել',
      onClick: () => handleClose(),
      type: 'submit',
    },
    {
      text: 'Հաստատել',
      onClick: () => handleSubmit(),
      type: 'submit',
      //disabled: ,
    },
  ];
  const onChangeData = (date) => {
    setFieldValue('salaryDate', dataTimeFormat(date));
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-new-paymentList-form"
    >
      <DateComponent
        openToDate={new Date(values?.salaryDate)}
        value={dateFormat(values?.salaryDate)}
        title="Ամսաթիվ"
        onChange={(date, e) => onChangeData(date, e)}
        name=""
      />
      <p className="bank-name">{values.bankName}</p>
      <div className="L-advance-payment-flex">
        <Checkbox
          checked={values.preSign}
          value={values.preSig}
          onChange={() => setFieldValue('preSign', !values.preSign)}
          label="Կանխավճար"
        />
        {values?.preSign && (
          <Input
            type="number"
            inputTitle="Դրույքի %"
            name="percent"
            requiredFiled={false}
            onChange={handleChange}
            value={values.percent}
            disabled={!values.preSign}
          />
        )}
      </div>
      <PaymentListsBtn
        buttonsConfig={buttonsConfig}
        className="L-new-paymentList-form-btn"
      />
    </Modal>
  );
};

export default NewPaymentListForm;
