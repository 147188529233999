import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchConfirmEmployeePosition,
  saveConfirmEmployeePosition,
} from '../services/fetchConfirmEmployeePosition';

const useConfirmEmployeePosition = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [employeePosition, setEmployeePosition] = useState([]);

  const getConfirmEmployeePosition = async (empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchConfirmEmployeePosition(currentCompanyID, empNo);
      setEmployeePosition(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addConfirmEmployeePosition = async (updateData, empNo) => {
    // const filtered = updateData?.filter(
    //   (item) => (item && item.isModifed === true) || item.tableID === 0,
    // );

    try {
      const { errorCode, message } = await saveConfirmEmployeePosition([
        ...updateData,
      ]);
      if (errorCode === 0) {
        getConfirmEmployeePosition(empNo);
      }
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setEmployeePosition('');
      setIsLoading(false);
    };
  }, []);

  return {
    getConfirmEmployeePosition,
    addConfirmEmployeePosition,
    employeePosition,
    setEmployeePosition,
    isLoading,
    error,
    errorMess,
    setErrorMess,
  };
};

export default useConfirmEmployeePosition;
