import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { confirmActionFields } from './FieldsConfig';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import DateComponent from '../../components/dateComponent/DateComponent';
import {
  dataTimeFormat,
  dateFormat,
  executeText,
} from '../../../App/Utilities/Utilities';
import Button from '../../../App/Components/Button/Button';

const ConfirmAction = ({
  confirmPositions,
  query,
  setQuery,
  onExecuteHandler,
}) => {
  let dropDownOptions = [];
  const handleDropdownChange = (fieldName, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }

    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: {
        ...prevQuery[fieldName],
        value: selectedOption.value,
        id: selectedOption.id?.trim(),
      },
    }));

    setQuery((prevQuery) => ({
      ...prevQuery,
    }));
  };

  const onDateChange = (date, fieldName) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: dataTimeFormat(date),
    }));
  };

  return (
    <SearchBackground>
      <div className="confirm-action-wrap-flex">
        <div className="confirm-action-wrap">
          {confirmActionFields?.map((field) => {
            const {
              fieldType,
              fieldName,
              required,
              Children,
              optionName,
              dropdownTitle,
              dateName,
              dateTitle,
              dropdownId,
              maxDate,
            } = field;

            if (typeof Children === 'function') {
              if (confirmPositions) {
                dropDownOptions = Children(confirmPositions[optionName]);
              }
            }

            switch (fieldType) {
              case 'dropdown':
                return (
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      handleDropdownChange(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                  />
                );
              case 'date':
                return (
                  <DateComponent
                    value={dateFormat(query[dateName])}
                    onChange={(date) => onDateChange(date, dateName)}
                    name={dateName}
                    title={dateTitle}
                    requiredFiled={required}
                    maxDate={maxDate}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
        <div className="G-add-btn">
          <Button type="button" text={executeText} onClick={onExecuteHandler} />
        </div>
      </div>
    </SearchBackground>
  );
};

export default ConfirmAction;
