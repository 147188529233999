import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../Hooks/useDebounce';
import AdvancedSearchBtn from './AdvancedSearchBtn';
import './search.scss';
import SearchInput from './SearchInput';

const SearchBar = (props) => {
  const {
    advancedSearchBtn = false,
    handelClick,
    openid,
    getData,
    pageNumber,
    searchValue = '',
    setSearchValue,
    setPageNumber,
    name,
    debounceDelay = 1000,
    searchFiledTitle
  } = props;

  const [typingTimeout, setTypingTimeout] = useState(null);
  const debouncedSearchTerm = useDebounce(searchValue, debounceDelay);

  const handleSearch = (e) => {
    const value = e.target.value;
    clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(() => {
        setPageNumber(0);
        setSearchValue(value);
      }, debounceDelay),
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchTerm.trim() !== '') {
        await getData(pageNumber, debouncedSearchTerm);
      } else {
        await getData(pageNumber);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, pageNumber]);

  return (
    <div
      className={`${advancedSearchBtn ? 'G-flex-justify-between' : 'G-search-form-input'
        } `}
    >
      <SearchInput
        searchFiledTitle={searchFiledTitle} value={searchValue} onChange={handleSearch} name={name} />

      {advancedSearchBtn ? (
        <AdvancedSearchBtn handelClick={handelClick} openid={openid} />
      ) : null}
    </div>
  );
};

export default SearchBar;