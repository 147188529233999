import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import checkIcon from '../../assets/icons/check.svg';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';
import PaymentListsBtn from './PaymentListsBtn';
import PaymentListTotal from './PaymentListTotal';
const BankPaymentListsTable = ({
  mainData,
  isDisabledButtons,
  paymentNum,
  confirmNewPaymentList,
  removePaymentList,
  setIsDisabledButtons,
  resetTableData,
  resetFields,
  currentBankCode,
}) => {
  const { remove, confirm } = isDisabledButtons || {};
  const [updateMainData, setUpdateMainData] = useState(mainData);
  const [checkSign, setCheckSign] = useState(false);

  useEffect(() => {
    const checkEveryItem = updateMainData?.every((item) => item.sign);
    setCheckSign(checkEveryItem);
  }, [updateMainData]);

  useEffect(() => {
    setUpdateMainData(mainData);
  }, [mainData]);
  const toggleCheckbox = (e, row = null) => {
    const { checked } = e.target;

    let updatedList = updateMainData.map((item) => ({
      ...item,
      sign:
        row && item.paymentListID === row.paymentListID
          ? checked
          : row
          ? item.sign
          : checked,
      confirmed:
        row && item.paymentListID === row.paymentListID
          ? false
          : item.confirmed,
    }));

    if (updatedList.every((item) => !item.sign)) {
      updatedList = updatedList.map((item) => ({ ...item, confirmed: false }));
    }

    setUpdateMainData(updatedList);
    setIsDisabledButtons((prevState) => ({ ...prevState, confirm: false }));
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 550 },
    },
    {
      title: 'Վճ N',
      cell: (row) => row.num,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.empName,
      customStyle: { maxWidth: 520, overflow: 'hidden' },
    },
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.calcDate),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Հ/Պ',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.dedAmount),
      customStyle: { maxWidth: 120 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox onChange={(e) => toggleCheckbox(e)} checked={checkSign} />
        </span>
      ),

      cell: (row) => (
        <Checkbox
          checked={row.sign}
          value={row.sign}
          onChange={(e) => toggleCheckbox(e, row)}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Հաստատված',
      cell: (row) =>
        row.confirmed ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 100 },
    },
  ];
  const buttonsConfig = [
    {
      text: 'Հաստատել',
      onClick: () => onConfirmHandler(),
      type: 'submit',
      disabled: confirm,
    },
    {
      text: 'Ջնջել',
      onClick: () => onRemoveHandler(),
      type: 'submit',
      disabled: remove,
    },
  ];

  const onConfirmHandler = async () => {
    try {
      await confirmNewPaymentList(updateMainData, paymentNum, currentBankCode);

      setIsDisabledButtons({
        ...isDisabledButtons,
        confirm: true,
      });
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const onRemoveHandler = async () => {
    try {
      await removePaymentList(paymentNum, resetTableData);
      resetFields();
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      {updateMainData !== null && updateMainData?.length ? (
        <>
          <Table
            customClass="L-payment-lists-table G-table-has-scroll"
            data={updateMainData}
            columnConfig={columnConfig}
          />
          <PaymentListTotal data={updateMainData} />
          <PaymentListsBtn
            buttonsConfig={buttonsConfig}
            className="L-payment-confirm-remove-btn"
          />
        </>
      ) : null}
    </>
  );
};

export default BankPaymentListsTable;
