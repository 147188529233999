import React, { useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import './style.scss';
import { useEffect } from 'react';
import useSalaryCalculation from '../../hooks/useSalaryCalculation';
import Alert from '../../../App/Components/Alert';
import SalaryCalculationAction from './SalaryCalculationAction';
import SalaryCalculationTable from './SalaryCalculationTable';
const SalaryCalculation = () => {
  const {
    getEmployeesForCalc,
    employeesForCalc,
    errorMess,
    setErrorMess,
    onCalculateData,
  } = useSalaryCalculation();
  const [values, setValues] = useState({});

  const [updateSalaryCalcData, setUpdateSalaryCalcData] = useState(
    employeesForCalc?.employeesForCalc,
  );

  useEffect(() => {
    setUpdateSalaryCalcData(employeesForCalc?.employeesForCalc);
  }, [employeesForCalc?.employeesForCalc]);

  useEffect(() => {
    getEmployeesForCalc(values.depNo);
  }, [values.depNo]);

  const onCalculateClick = async () => {
    const filterItem = updateSalaryCalcData
      .filter((i) => i.sign === true)
      .map((i) => ({
        companyID: i.companyID,
        sign: i.sign,
        empNo: i.empNo,
      }));

    await onCalculateData(values.depNo, filterItem);
  };

  return (
    <>
      <Header
        headerBtn={false}
        pageTitle="Աշխատավարձի հաշվարկ"
        showCalculationDate={true}
      />
      <SalaryCalculationAction
        employeesForCalc={employeesForCalc}
        values={values}
        setValues={setValues}
        onCalculateClick={onCalculateClick}
      />
      <SalaryCalculationTable
        updateSalaryCalcData={updateSalaryCalcData}
        setUpdateSalaryCalcData={setUpdateSalaryCalcData}
      />

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default SalaryCalculation;
