import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { actionConfigs } from './actionConfigs';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
import Button from '../../../App/Components/Button/Button';
import {
  dataTimeFormat,
  dateFormat,
  executeText,
  filterObjKeys,
} from '../../../App/Utilities/Utilities';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import DateComponent from '../../components/dateComponent/DateComponent';

const GeneralSummaryAction = ({
  query,
  setQuery,
  generalSummaryData,
  getExecuteGeneralSummary,
  summariesExcelData,
  salarySummaries,
  resetTableData,
}) => {
  const optionsData = generalSummaryData || [];

  const handleDropdownChange = async (selectedOption, config) => {
    const { optionName, optionID } = config || {};
    const option = selectedOption || { value: '', id: null };
    resetTableData();
    setQuery((prevQuery) => ({
      ...prevQuery,
      [optionName]: option.value,
      [optionID]: option.id,
    }));
  };
  const onExecuteClick = async () => {
    const keysToRemove = ['salaryMonths', 'workDeps', 'years'];

    const modifyObj = filterObjKeys(query, keysToRemove);

    await getExecuteGeneralSummary(modifyObj);
  };
  const handleCheckboxChange = (event, name) => {
    const { checked } = event.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: checked,
    }));
  };

  const handleDateChange = (date, name) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: dataTimeFormat(date),
    }));
  };
  return (
    <SearchBackground>
      <div className="L-general-summary-action-wrap">
        <div className="L-general-summary-filed-flex">
          {actionConfigs?.map((config, index) => {
            if (config.type === 'dropdown') {
              const dropDownOptions = optionsData
                ? config.Children(optionsData[config.optionName])
                : [];

              return (
                <div className="L-general-summary-filed" key={index}>
                  <SelectControl
                    value={{ label: query[config.optionName] }}
                    selectBoxTitle={config.title}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      handleDropdownChange(selectedOption, config)
                    }
                    requiredFiled={config.required}
                  />
                </div>
              );
            } else if (config.type === 'calendar') {
              return (
                <div className="G-Summary-calendar-wrap" key={index}>
                  <p className="G-title-p">{config.title}</p>
                  <div className="G-flex">
                    {config.calendars?.map((i, calendarIndex) => (
                      <div className="calendar-box" key={calendarIndex}>
                        <DateComponent
                          value={dateFormat(query[i.name])}
                          onChange={(date) => handleDateChange(date, i.name)}
                          name={i.name}
                          requiredFiled={false}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            } else if (config.type === 'checkbox') {
              return (
                <div className="L-general-summary-filed" key={index}>
                  <Checkbox
                    name={String(config.name)}
                    label={config.title}
                    checked={query[config.name]}
                    onChange={(e) => handleCheckboxChange(e, config.name)}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
        <div
          style={{ width: '10%' }}
          className="G-justify-between-align-center"
        >
          <div className="G-add-btn">
            <Button type="button" text={executeText} onClick={onExecuteClick} />
          </div>

          {salarySummaries?.length > 0 && (
            <ExportExcel excelData={summariesExcelData} fileName="" />
          )}
        </div>
      </div>
    </SearchBackground>
  );
};

export default GeneralSummaryAction;
