import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../../App/Components/Modal/Modal';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import { AssignAdditionalFields } from './FieldsConfig';
import Input from '../../../Input/Input';
import ErrorFiled from '../../../../App/Components/ErrorFiled/ErrorFiled';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import DateComponent from '../../../components/dateComponent/DateComponent';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { AssignPositionModalValidation } from '../../../../App/Validation/AssignAdditionalValidation';
import NumericFormatFiled from '../../../../App/Components/NumericFormat/NumericFormatFiled';
import useIsMounted from '../../../hooks/useIsMounted';
import {
  dataTimeFormat,
  dateFormat,
} from '../../../../App/Utilities/Utilities';
import Alert from '../../../../App/Components/Alert';

const AssignPositionForm = ({
  empNo,
  salarySetID = 0,
  onSubmit,
  handleClose,
}) => {
  const {
    getAssignAdditionalStorageFormData,
    assignStorageFormData,
    errorMess,
    setErrorMess,
  } = useAssignAdditionalStorage();

  const isMounted = useIsMounted();

  const sign = true;
  useEffect(() => {
    getAssignAdditionalStorageFormData(empNo, salarySetID, sign);
  }, [empNo, salarySetID, sign]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...assignStorageFormData?.salarySet,
    },
    validationSchema: AssignPositionModalValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);

      if (isMounted) {
        resetForm();
      }
    },
  });

  let dropDownOptions = [];

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.label;
    const dropdownItemId = selectedOption.id;
    const graphName = selectedOption.graphName;
    const graphNo = selectedOption.graphNo;
    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
    setFieldValue('graphName', graphName);
    setFieldValue('graphNo', graphNo);
  };
  const onChangeDate = (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      return;
    }

    setFieldValue(name, dataTimeFormat(date));
  };
  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };

  return (
    <>
      {errorMess ? (
        <Alert description={errorMess} onClose={handleAlertClose} />
      ) : (
        <Modal
          closeHandler={handleClose}
          customClass="assign-additional-form-block"
        >
          <div className="assign-additional-form-content">
            {AssignAdditionalFields?.map((field) => {
              const {
                fieldType,
                fieldName,
                label,
                name,
                required,
                Children,
                optionName,
                dropdownTitle,
                dateName,
                type,
                dropdownId,
                flexFiled,
                disabled,
              } = field;

              const fieldError = touched[name] && errors[name];

              if (typeof Children === 'function') {
                if (assignStorageFormData) {
                  dropDownOptions = Children(assignStorageFormData[optionName]);
                }
              }

              switch (fieldType) {
                case 'input':
                  return (
                    <div key={name} className="input-container">
                      <Input
                        type={type}
                        inputTitle={label}
                        name={name}
                        requiredFiled={required}
                        onChange={handleChange}
                        value={values[name]}
                        onBlur={handleBlur}
                        disabled={assignStorageFormData?.firstSign}
                      />
                      {fieldError && <ErrorFiled error={fieldError} />}
                    </div>
                  );
                case 'amount':
                  return (
                    <div key={name} className="input-container">
                      <NumericFormatFiled
                        inputTitle={label}
                        name={name}
                        requiredFiled={required}
                        changeHandler={handleChange}
                        value={values[name]}
                        onBlur={handleBlur}
                        disabled={disabled}
                      />
                      {fieldError && <ErrorFiled error={fieldError} />}
                    </div>
                  );

                case 'dropdown':
                  return (
                    <div key={fieldName} className="select-container">
                      <SelectControl
                        selectBoxTitle={dropdownTitle}
                        requiredFiled={required}
                        value={{
                          label: values[fieldName] || '',
                          value: values[fieldName] || '',
                        }}
                        options={dropDownOptions || []}
                        onChange={(selectedOption) =>
                          updateFieldValueAndDropdown(
                            fieldName,
                            selectedOption,
                            dropdownId,
                          )
                        }
                        onBlur={handleBlur}
                      />
                      {required && !values[fieldName] && fieldError && (
                        <ErrorFiled error={fieldError} />
                      )}
                    </div>
                  );
                default:
                  return (
                    <div className="flex-field-container" key={fieldName}>
                      {flexFiled.map((flexField, index) => (
                        <div key={index} className="flex-field-item">
                          {flexField?.fieldType === 'date' ? (
                            <DateComponent
                              value={dateFormat(
                                values[flexField.dateName] || '',
                              )}
                              onChange={(date) =>
                                onChangeDate(date, flexField.dateName)
                              }
                              name={dateName}
                              title={flexField.dateTitle}
                              requiredFiled={flexField.required}
                              openToDate={new Date(values[flexField.dateName])}
                              disabled={assignStorageFormData?.firstSign}
                            />
                          ) : (
                            <Input
                              type={flexField.type}
                              inputTitle={flexField.label}
                              name={flexField.name}
                              requiredFiled={flexField.required}
                              onChange={handleChange}
                              value={values[flexField.name]}
                              onBlur={handleBlur}
                              disabled={flexField.disabled(values)}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  );
              }
            })}
            <GetSentDataBtn
              cancelPrintClick={handleClose}
              confirmExecuteClick={handleSubmit}
              executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
              executeDisabled={!dirty || !isValid}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AssignPositionForm;
