import React, { useEffect, useState } from 'react';
import EmployeePositionHeader from './EmployeePositionHeader';
import EmployeePositionTable from './EmployeePositionTable';
import './style.scss';
import useConfirmEmployeePosition from '../../hooks/useConfirmEmployeePosition';

const ConfirmEmployeePosition = ({ handleClose, rowValues }) => {
  const {
    getConfirmEmployeePosition,
    employeePosition,
    errorMess,
    setErrorMess,
    addConfirmEmployeePosition,
  } = useConfirmEmployeePosition();
  const [isDisabledConfBtn, setIsDisabledConfBtn] = useState(false);

  useEffect(() => {
    const isConfirmed = employeePosition?.tables?.some(
      (row) => row.tableID > 0,
    );
    setIsDisabledConfBtn(isConfirmed);
  }, [employeePosition?.tables]);

  useEffect(() => {
    getConfirmEmployeePosition(rowValues?.empNo);
  }, [rowValues?.empNo]);

  return (
    <div className="L-confirm-employee-position">
      <EmployeePositionHeader data={rowValues} />
      <EmployeePositionTable
        mainData={employeePosition?.tables}
        handleClose={handleClose}
        isDisabledConfBtn={isDisabledConfBtn}
        setIsDisabledConfBtn={setIsDisabledConfBtn}
        empNo={rowValues?.empNo}
        errorMess={errorMess}
        setErrorMess={setErrorMess}
        addConfirmEmployeePosition={addConfirmEmployeePosition}
      />
    </div>
  );
};

export default ConfirmEmployeePosition;
