import React from 'react';
import Alert from '../../../../App/Components/Alert';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import AssignPositionForm from './AssignPositionForm';
import useIsMounted from '../../../hooks/useIsMounted';
import { removeCommas } from '../../../../App/Utilities/Utilities';
import { useDispatch } from 'react-redux';
import { updateWithEmployeesSalaryAction } from '../../../../App/Redux/SalaryStor/workWithEmployeesSlices/useAction';
const EventAddForm = ({ rowValues, handleClose }) => {
  const { empNo } = rowValues || {};
  const { addAssignAddition, errorMess, setErrorMess } =
    useAssignAdditionalStorage();

  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const sign = true;
  const onAddHandler = async (values, resetForm) => {
    const amount = removeCommas(values.sAmount);
    const val = {
      ...values,
      sPercent: Number(values.sPercent),
      sAmount: amount,
    };

    try {
      await addAssignAddition(val, handleClose, empNo, sign);

      if (isMounted && !errorMess) {
        if (values.sAmount) {
          dispatch(
            updateWithEmployeesSalaryAction({
              empNo,
              sAmount: amount,
            }),
          );
        }
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <>
      <AssignPositionForm
        onSubmit={onAddHandler}
        handleClose={handleClose}
        empNo={empNo}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
