export const actionList = (isClosed) => {
  const actionList = [];
  if (isClosed) {
    actionList.push(
      {
        name: 'Գործառնություն',
        value: 'operations',
      },

      {
        name: 'Ջնջել',
        value: 'delete',
      },
      {
        name: 'Դիտել/տպել',
        value: 'print',
      },
      {
        name: 'Բացել փաստաթուղթը',
        value: 'closeDocument',
      },
    );
  } else {
    actionList.push(
      {
        name: 'Խմբագրել',
        value: 'edit',
      },
      {
        name: 'Գործառնություն',
        value: 'operations',
      },

      {
        name: 'Ջնջել',
        value: 'delete',
      },
      {
        name: 'Դիտել/տպել',
        value: 'print',
      },
      {
        name: 'Փակել փաստաթուղթը',
        value: 'closeDocument',
      },
    );
  }

  return actionList;
};
