import React from 'react';
import EventEditForm from './EventEditForm';
import DeleteAssignPositionItem from './DeleteAssignPositionItem';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'delete':
      return (
        <DeleteAssignPositionItem
          id={value?.salarySetID}
          handleClose={handleClose}
        />
      );
    case 'edit':
      return <EventEditForm rowValue={value} handleClose={handleClose} />;

    default:
      return null;
  }
};

export default Forms;
