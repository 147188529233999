import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest } from '../../Api/Api';
import { getSpendingMoneyDataAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import Checkbox from '../../Components/Checkbox/Checkbox';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import {
  checkBeforeDelete,
  Commas,
  dateFormat,
  printHandler,
  tableActionTitle,
} from '../../Utilities/Utilities';
import Tooltip from '../../Components/Tooltip/Tooltip';
import Action from '../../Components/Table/Action';
import EditSendingMoneyWrapper from './EditSendingMoneyWrapper';
import CloseDocument from './CloseDocument';
import DeleteDocument from './DeleteDocument';
import GetSpendingOperations from './SpendingOperations/GetSpendingOperations';
import { actionList } from './Actionlist';
import Alert from '../../Components/Alert';

const AccountSpendingMoneyTable = ({ mainData, loading }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [closeDocument, setCloseDocument] = useState(false);
  const [openOperationsModal, setOpenOperationsModal] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const [isCloseDocument, setIsCloseDocument] = useState(false);
  const dispatch = useDispatch();

  const handelChange = (value, row) => {
    switch (value) {
      case 'operations':
        return openOperationsForm(row);
      case 'delete':
        return openDeleteForm(row.cashMoneyID);
      case 'edit':
        return openEditForm(row);
      case 'print':
        return handlePrint(row.partnerID, row?.cashMoneyID);
      case 'closeDocument':
        return closeDocumentHandler(row);
      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.creationDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Հաշվետու անձ',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 450, overflow: 'hidden' },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.basis,
      customStyle: { maxWidth: 350, overflow: 'hidden' },
    },

    {
      title: <Tooltip content="Սկզբնական մնացորդ">Սկզբն.մն.</Tooltip>,
      cell: (row) => Commas(row.initBalance),
      customStyle: { maxWidth: 140 },
    },
    {
      title: <Tooltip content="Տրամադրված գումար">Տրամադրված</Tooltip>,
      cell: (row) => Commas(row.total),
      customStyle: { maxWidth: 130 },
    },
    {
      title: <Tooltip content="Ծախսված գումար">Ծախսված</Tooltip>,
      cell: (row) => Commas(row.expense),
      customStyle: { maxWidth: 140 },
    },

    {
      title: <Tooltip content="Վերադարձված գումար">Վերադարձված</Tooltip>,
      cell: (row) => Commas(row.inputs),
      customStyle: { maxWidth: 140 },
    },

    {
      title: <Tooltip content="Վերջնական մնացորդ">Վերջն.մն.</Tooltip>,
      cell: (row) => Commas(row.finalBalance),
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'Փակված է',
      cell: (row) => (
        <Checkbox
          checked={row.isClosed}
          value={row.isClosed}
          onChange={() => setIsCloseDocument(row)}
          disabled={true}
        />
      ),
      customStyle: { maxWidth: 70, justifyContent: 'center' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={actionList(row?.isClosed)}
          mainAction={row?.isClosed ? 'delete' : 'edit'}
          mainActionName={row?.isClosed ? 'Ջնջել' : 'Խմբագրել'}
          modalIsOpen={
            openDeleteModal ||
            openEditModal ||
            openOperationsModal ||
            closeDocument ||
            isCloseDocument
          }
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const fetchEditData = (row) => {
    if (row.partnerID && row.cashMoneyID) {
      getRequest(
        `getExpenses?partnerID=${row.partnerID}&CashMoneyID=${row.cashMoneyID}`,
      )
        .then((res) => {
          if (res.data) {
            dispatch(getSpendingMoneyDataAction(res.data));
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    } else {
      return null;
    }
  };

  const openEditForm = (rowData) => {
    fetchEditData(rowData);
    setOpenEditModal(rowData?.cashMoneyID);
  };

  const openDeleteForm = (deleteRowId) => {
    checkBeforeDelete(`letDeleteCashMoney?cashMoneyID=${deleteRowId}`).then(
      (res) => {
        if (res.errorCode === 0) {
          setOpenDeleteModal(deleteRowId);
        }
        if (res.errorCode > 0) {
          setErrorHandler(res.message);
        }
      },
    );
  };
  const closeDocumentHandler = (row) => {
    setCloseDocument(row);
  };
  const openOperationsForm = (row) => {
    setOpenOperationsModal(row);
  };

  const handlePrint = (partnerID, cashMoneyID) => {
    printHandler(
      `printCashMoney?partnerID=${partnerID}&cashMoneyID=${cashMoneyID}`,
      dispatch,
    );
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-account-sending-money-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openEditModal && (
        <EditSendingMoneyWrapper
          closeModal={setOpenEditModal}
          getCashMoneyId={openEditModal}
        />
      )}

      {closeDocument && (
        <CloseDocument
          closeModal={setCloseDocument}
          closeDocumentData={closeDocument}
        />
      )}

      {openDeleteModal && (
        <DeleteDocument
          closeModal={setOpenDeleteModal}
          deleteRowId={openDeleteModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      {openOperationsModal && (
        <GetSpendingOperations
          rowData={openOperationsModal}
          closeModal={setOpenOperationsModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />

      {isCloseDocument && (
        <CloseDocument
          closeDocumentData={isCloseDocument}
          closeModal={setIsCloseDocument}
        />
      )}
    </>
  );
};

export default AccountSpendingMoneyTable;
