import { dateFormat } from '../../Utilities/Utilities';

export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        Ամսաթիվ: dateFormat(i.docDate),
        'Փաստաթղթի համարը': i.docNum,
        Տեսակը: i.docTypeName,
        Գործընկեր: i.partnerName,
        Արժույթ: i.currencyCode,
        Գումարը: i.amount,
        Հիմքը: i.docBase,
        'Հաշվարկային հաշիվ': i.docBase,
        'Գործընկերոջ հ/հաշիվ': i.partnerAccount,
      };
    })
  );
};

export default ConvertDataExcel;
