import React, { useEffect, useMemo } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import PrintAndFormulationsBtn from './PrintAndFormulationsBtn';
import FormulationDropdown from './FormulationDropdown';
import { getMonthOptions, getYeasOptions } from './optionsUtils';
import { printHandler } from '../../../App/Utilities/Utilities';
import { useDispatch, useSelector } from 'react-redux';

const AccountingFormulationAction = ({
  query,
  setQuery,
  onNewFormulationsHandler,
  accountFormulationData,
  resetFormulationData,
  getSalaryMonths,
  salaryMonthData,
  getDateOfFormation,
  isDisabledButtons,
}) => {
  const { newFormulations, print } = isDisabledButtons;
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const CALCULATION_YEAR = 'calculationYear';
  const CALCULATION_MONTH = 'calculationMonth';

  const yearOptions = useMemo(() => {
    return getYeasOptions(accountFormulationData?.years);
  }, [accountFormulationData?.years]);

  const monthOptions = useMemo(() => {
    return getMonthOptions(salaryMonthData?.salaryMonths);
  }, [salaryMonthData?.salaryMonths]);

  const onPrintHandler = () => {
    const { years, month } = query || {};
    if (!years || !month) {
      return;
    }
    const url = `printAccStatements?companyID=${currentCompanyID}&year=${years}&month=${month}`;

    printHandler(url, dispatch);
  };

  const dropdownConfigs = [
    {
      value: { label: query.years },
      name: CALCULATION_YEAR,
      title: 'Հաշվարկի տարին',
      options: yearOptions,
      required: true,
    },
    {
      value: { label: query.salaryMonthName },
      name: CALCULATION_MONTH,
      title: 'Հաշվարկի ամիսը',
      options: monthOptions,
      required: true,
    },
  ];

  const handleDropdownChange = async (selectedOption, name) => {
    const option = selectedOption || { value: '', id: null };
    resetFormulationData();
    const { year, month } = selectedOption || {};

    if (name === CALCULATION_YEAR) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        years: option.value,
      }));
    } else if (name === CALCULATION_MONTH) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        salaryMonthName: option.value,
        yearMonth: option.yearMonth,
        years: option.year,
        month: option.month,
      }));

      await getDateOfFormation({ year, month });
    }
  };

  useEffect(() => {
    if (query.years) {
      getSalaryMonths(query.years);
    }
  }, [query.years]);

  return (
    <SearchBackground>
      <div className="L-accounting-formulation-action-flex">
        <FormulationDropdown
          dropdownConfigs={dropdownConfigs}
          handleDropdownChange={handleDropdownChange}
        />
        <PrintAndFormulationsBtn
          isFormulationsDisabled={newFormulations}
          isPrintDisabled={print}
          onPrintHandler={onPrintHandler}
          onNewFormulationsHandler={onNewFormulationsHandler}
        />
      </div>
    </SearchBackground>
  );
};

export default AccountingFormulationAction;
