import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import {
  getPaymentOrderAction,
  loadingAction,
} from '../../Redux/paymentOrderSlices/useAction';
import {
  correctDate,
  dataTimeFormat,
  inCorrectDateMess,
  initialDate,
} from '../../Utilities/Utilities';
import EventAddForm from './EventAddForm';
import PaymentOrderTable from './PaymentOrderTable';
import './paymentOrder.scss';
import Alert from '../../Components/Alert';
import TotalAmount from '../../Components/TotalAmount/TotalAmount';

const GetPaymentOrderData = () => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);
  const { paymentOrderData, isLoading } = useSelector(
    (state) => state.getPaymentOrderData,
  );
  const { currentId } = useSelector((state) => state.filteredId);
  const [dateStart, setDateStart] = useState(new Date(startDate));

  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [pageNumber, setPageNumber] = useState(0);
  const [openPaymentOrderForm, setOpenPaymentOrderForm] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();

  const getData = (pageNumber) => {
    dispatch(loadingAction(true));
    getRequest(
      `getpaymentorders?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&documentID=${currentId}`,
    )
      .then((res) => {
        dispatch(getPaymentOrderAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, dispatch, dateStartFormat, dateEndFormat]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };
  const addFormHandler = () => {
    setOpenPaymentOrderForm(true);
  };

  return (
    <>
      <Header pageTitle="Վճարման հանձնարարագիր" handelClick={addFormHandler} />
      <SearchBackground>
        <div className="G-flex-justify-center">
          <CalendarTime
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            onChangeHandler={timeChangeHandler}
          />
        </div>
      </SearchBackground>

      <PaymentOrderTable
        mainData={paymentOrderData?.paymentOrders}
        loading={isLoading}
        setErrorHandler={setErrorHandler}
        getData={getData}
        pageNumber={pageNumber}
      />
      <TotalAmount arr={paymentOrderData?.paymentOrders} />
      <Pagination
        pageCount={paymentOrderData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openPaymentOrderForm && (
        <EventAddForm
          closeModal={setOpenPaymentOrderForm}
          getData={getData}
          pageNumber={pageNumber}
          setErrorHandler={setErrorHandler}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetPaymentOrderData;
