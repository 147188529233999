import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { dateFormat, removeCommas } from '../../../App/Utilities/Utilities';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';

const AssignPositionsTable = ({
  updateData,
  setUpdateData,
  isRegister,
  setIsDisabled,
}) => {
  const handleChangeAmount = (e, targetRow) => {
    const { value, name } = e.target;
    const newValue = removeCommas(value);

    if (newValue <= 0) {
      setIsDisabled(true);
      return;
    }

    const updatedRows = updateData?.salarySets?.map((row) => {
      if (targetRow.empNo === row.empNo) {
        const updatedRow = {
          ...row,
          [name]: newValue,
        };

        return updatedRow;
      }
      return row;
    });

    setUpdateData((prev) => ({
      ...prev,
      salarySets: updatedRows,
    }));
    setIsDisabled(false);
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 450 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.empName,
      customStyle: { maxWidth: 800 },
    },
    {
      title: 'Ընդունման ամս.',
      cell: (row) => dateFormat(row.hireDate),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Ընթ. հավելում',
      cell: (row) => row.oldSTNo,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Ընթ. ամսաթիվը',
      cell: (row) => dateFormat(row.oldSetDate),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Ընթացիկ հաստիքը',
      cell: (row) => (
        <NumericFormatFiled
          value={row.oldSalary}
          allowNegative={true}
          name="oldSalary"
          changeHandler={(e) => handleChangeAmount(e, row)}
          inputTitle=""
        />
      ),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Նոր հաստիքը',
      cell: (row) => (
        <NumericFormatFiled
          value={row.sAmount}
          allowNegative={true}
          name="sAmount"
          changeHandler={(e) => handleChangeAmount(e, row)}
          inputTitle=""
          //style={{ fontWeight: row.sAmount > 0 ? 'bold' : 'inherit' }}
          disabled={row.error}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  const modifiedSalarySetData = updateData?.salarySets?.map((row) => ({
    ...row,
    rowClass: row.error ? 'row-has-red-bg' : '',
  }));
  return (
    <>
      {modifiedSalarySetData !== null && modifiedSalarySetData?.length ? (
        <Table
          customClass="L-assign-positions-table G-table-has-scroll"
          data={modifiedSalarySetData}
          columnConfig={columnConfig}
        />
      ) : (
        <p className="L-assign-positions-mess">{updateData?.message}</p>
      )}
    </>
  );
};

export default AssignPositionsTable;
