import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Excel from '../../Assets/icons/excel.png';
import { routing } from '../../Routing/routing';

export const ExportExcel = ({ excelData, fileName = '' }) => {
  const getPageName = (path) => {
    for (const route of routing) {
      if (route.path === path) {
        return route.name;
      } else if (route.chillers) {
        for (const chiller of route.chillers) {
          if (chiller.path === path) {
            return chiller.name;
          }
        }
      }
    }
    return '';
  };

  const currentPath = window.location.pathname;
  const pageName = getPageName(currentPath);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName ? fileName : pageName + fileExtension);
  };

  return (
    <div className="L-excel-icon" onClick={exportToExcel}>
      <img src={Excel} alt="Excel Export" />
    </div>
  );
};
