import React, { useEffect, useState } from 'react';
import useWorkWithEmployees from '../../hooks/useWorkWithEmployees';
import WorkWithEmployeesTable from './WorkWithEmployeesTable';
import Header from '../../../App/Components/Header/Header';
import AdvancedSearch from './AdvancedSearch';
import './style.scss';
import { simplifyObject } from '../../../App/Utilities/Utilities';
import Pagination from '../../../App/Components/Pagination/Pagination';
const WorkWithEmployees = () => {
  const { getWorkWithEmployees, workWithEmployees, isLoading } =
    useWorkWithEmployees();
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState({
    takeCount: null,
    searchString: '',
    fireSign: false,
    empNo: '',
    depNo: '',
  });

  const modifyObj = simplifyObject(query);
  useEffect(() => {
    getWorkWithEmployees(modifyObj, pageNumber);
  }, [query, pageNumber]);

  return (
    <div className="main-b">
      <Header
        pageTitle="Գործողություններ աշխատակիցների հետ"
        headerBtn={false}
        showCalculationDate={true}
      />
      <AdvancedSearch
        query={query}
        setQuery={setQuery}
        workWithEmployees={workWithEmployees}
      />
      <WorkWithEmployeesTable
        mainData={workWithEmployees?.employees}
        loading={isLoading}
      />
      <Pagination
        pageCount={workWithEmployees?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </div>
  );
};

export default WorkWithEmployees;
