import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import {
  dataTimeFormat,
  debounceDelay,
  initialDate,
  printHandler,
} from '../../Utilities/Utilities';
import TransactionLogAction from './TransactionLogAction';
import TransactionLogTable from './TransactionLogTable';
import './transactionLog.scss';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import { useDebounce } from '../../Hooks/useDebounce';

const GetTransactionLogData = () => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [transactionLogData, setTransactionLogData] = useState([]);
 const { data } = useFetchCurrency();

  const dispatch = useDispatch();
  const [currencyVal, setCurrencyVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [docTypesVal, setDocTypesVal] = useState({
    label: '',
    value: '',
    id: 0,
  });

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, debounceDelay);

  const generalUrl = useMemo(() => {
    return `companyID=${currentCompanyID}&currencyID=${
      currencyVal.id === '' ? data?.amdCurrencyID : currencyVal.id
    }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&searchString=${debouncedSearchTerm.trim()}`;
  }, [
    currentCompanyID,
    currencyVal.id,
    dateStartFormat,
    dateEndFormat,
    debouncedSearchTerm,
    data?.amdCurrencyID,
  ]);


  useEffect(() => {
    setIsLoading(true);
    getRequest(`getEntryBook?${generalUrl}&docTypeID=${docTypesVal.id}`)
      .then((res) => {
        setTransactionLogData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [generalUrl, docTypesVal.id]);

  const handlePrint = useCallback(() => {
    printHandler(
      `printEntryBook?${generalUrl}&docTypeID=${docTypesVal.id}`,
      dispatch,
    );
  }, [generalUrl, docTypesVal.id, dispatch]);

  return (
    <>
      <Header headerBtn={false} pageTitle="Գործառնությունների մատյան" />
      <SearchBackground>
        <TransactionLogAction
          printHandler={handlePrint}
          mainData={transactionLogData?.entries}
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
          currencyVal={currencyVal}
          setCurrencyVal={setCurrencyVal}
          docTypesVal={docTypesVal}
          setDocTypesVal={setDocTypesVal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </SearchBackground>
      <TransactionLogTable
        mainData={transactionLogData?.entries}
        loading={isLoading}
      />
    </>
  );
};

export default GetTransactionLogData;
