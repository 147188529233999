import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { editOrganizationAction } from '../../Redux/organizationSlices/useAction';
import OrganizationForm from './OrganizationForm';
import { updateUser } from '../../Redux/authSlices/useAction';

const EventEditForm = () => {
  const { organizationData } = useSelector(
    (state) => state.getOrganizationData,
  );

  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevCompanyName, setPrevCompanyName] = useState('');
  const [existingDate, setExistingDate] = useState({
    startDate: '',
    endDate: '',
    taxationID: '',
    taxationSystemName: '',
  });

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      putRequest('editCompany', values)
        .then((res) => {
          const {
            errorCode,
            message,
            oldDate,
            startDate,
            endDate,
            companyTaxationID,
            taxationSystemName,
          } = res.data;

          if (errorCode > 0) {
            setErrorHandler(message);
          }
          if (errorCode === 10) {
            const updatedCompanyData = {
              ...organizationData.company,
              taxationDate: oldDate,
              taxationID: companyTaxationID,
              taxationSystemName: taxationSystemName,
            };
            dispatch(editOrganizationAction({ ...updatedCompanyData }));
            setExistingDate({
              startDate,
              endDate,
              companyTaxationID,
              taxationSystemName,
            });
          } else if (errorCode === 0) {
            setErrorHandler('Փոփոխությունը գրանցվեց');
            dispatch(editOrganizationAction(values));
            dispatch(
              updateUser({
                startDate: values.startDate,
              }),
            );
          } else if (values.companyName !== prevCompanyName) {
            dispatch(
              updateUser({
                currentCompanyName: values.companyName,
                currentCompanyID: values.companyID,
              }),
            );
          }
        })
        .catch((err) => {
          console.log('err' + err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [dispatch, prevCompanyName],
  );

  const memoizedInitialData = useMemo(
    () => organizationData?.company,
    [organizationData],
  );

  useEffect(() => {
    if (memoizedInitialData) {
      setPrevCompanyName(memoizedInitialData.companyName);
    }
  }, [memoizedInitialData]);

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, []);

  return (
    <>
      <OrganizationForm
        initialData={memoizedInitialData}
        onSubmit={onSubmit}
        disabledTaxCode={true}
        setErrorHandler={setErrorHandler}
        isSubmitting={isSubmitting}
        existingDate={existingDate}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
