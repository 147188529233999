import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Modal from '../../../Components/Modal/Modal';
import { CashWarrantFormValidation } from '../../../Validation/CashWarrantValidation';
import { updatePartnerAction } from '../../../Redux/cashWarrantSlices/useAction';
import InputFiled from '../../../Components/InputFiled/InputFiled';
import ErrorFiled from '../../../Components/ErrorFiled/ErrorFiled';
import Calendar from '../../../Components/Calendar/Calendar';
import SelectPartner from '../../../Components/SelectBox/SelectPartner';
import { getRequest } from '../../../Api/Api';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';
import {
  dataTimeFormat,
  dateFormat,
  exceedAmount,
  exceedAmountMess,
  removeCommas,
  textFiledLength,
} from '../../../Utilities/Utilities';
import SelectAccount from '../../../Components/SelectBox/SelectAccount';
import { default as PartnerForm } from '../../../Pages/Partners/EventAddForm';
import AddNew from '../../../Elements/Add/AddNew';
import NumericFormatFiled from '../../../Components/NumericFormat/NumericFormatFiled';
import DocNumFiled from '../../../Components/DocNumFiled';
import RegisterCalculationInReport from '../../../Components/RegisterCalculationInReport/RegisterCalculationInReport';

const CashWarrantForm = (props) => {
  const {
    handleClose,
    initialValue,
    onSubmit,
    setErrorHandler,
    addEvent = false,
  } = props;
  const { cashWarrantData } = useSelector((state) => state.getCashWarrantData);
  const { amdCurrID } = cashWarrantData || {};
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [correspondentAccount, setCorrespondentAccount] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(initialValue?.exchangeRate);
  const [cashAccount, setCashAccount] = useState([]);
  const [showPartnerForm, setShowPartnerForm] = useState(false);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...initialValue,
    },
    validationSchema: CashWarrantFormValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  useEffect(() => {
    setFieldValue('exchangeRate', exchangeRate);
    setFieldValue('amountAMD', values.amount);
  }, [exchangeRate, setFieldValue, values.amount]);

  const changAccount = values.currencyID === amdCurrID ? 2511 : 2512;
  useEffect(() => {
    if (values.currencyCode !== 'AMD' || values?.credCurrCode !== 'AMD') {
      getRequest(
        `getRate?rateDate=${dataTimeFormat(values?.docDate)}&iso=${
          values?.currencyCode === 'AMD'
            ? values?.credCurrCode
            : values?.currencyCode
        }`,
      )
        .then((res) => {
          if (res.data) {
            setExchangeRate(res.data);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
    if (values.currencyCode === 'AMD' || values?.credCurrCode === 'AMD') {
      setExchangeRate(1);
    }
  }, [
    values.currencyID,
    values.currencyCode,
    values?.docDate,
    values?.credCurrCode,
  ]);

  useEffect(() => {
    const havePartner = values?.partnerID ? true : false;
    if (values?.partnerID === 0 || havePartner) {
      getRequest(
        `getCorrespAccounts?companyID=${currentCompanyID}&accountCode=${251}&currencyID=${
          values?.currencyID
        }&debitCredit=${1}&partner=${havePartner}&partnerID=${
          values.partnerID
        }`,
      )
        .then((res) => {
          setCorrespondentAccount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    } else if (values?.partnerID !== 0) {
      getRequest(`getaccountsforpartner?companyID=${currentCompanyID}`)
        .then((res) => {
          setCorrespondentAccount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [
    cashWarrantData?.creditAccounts,
    currentCompanyID,
    values?.currencyID,
    values?.partnerID,
  ]);

  useEffect(() => {
    if (values?.currencyID) {
      getRequest(
        `getCompanyAccounts?companyID=${currentCompanyID}&accountCode=${changAccount}&currencyID=${values?.currencyID}`,
      )
        .then((res) => {
          setCashAccount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [changAccount, currentCompanyID, values?.currencyID]);

  const handleChangeDebitAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }

    setFieldValue('accountDebit', values.value);
  };

  const handleChangePartner = (values) => {
    if (!values) {
      values = {
        value: '',

        id: 0,
      };
      setFieldValue('recipient', '');
      setFieldValue('correspAccount', '');
    }
    setFieldValue('recipient', values.value);

    setFieldValue('partnerType', values.partnerType);
    setFieldValue('partnerID', values.id);
    setFieldValue('partnerName', values.value);
    setFieldValue('taxCode', values.taxCode);
  };

  const handleChangeCorrespondentAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('correspAccount', values.value);
  };

  const onChangeData = (date) => {
    setFieldValue('docDate', date);
  };

  const openPartnerAddForm = () => {
    setShowPartnerForm(true);
  };

  let corrAccount = values.correspAccount.includes('252');

  const amountIsExceeded =
    !corrAccount && removeCommas(values.amount) > exceedAmount ? true : false;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (amountIsExceeded) {
        setErrorHandler(exceedAmountMess);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [corrAccount, amountIsExceeded, values.amount]);

  const newPartner = ({ partnerID, partnerName }) => {
    setFieldValue('partnerID', partnerID);
    setFieldValue('partnerName', partnerName);
  };

  const bankAccount = ['252'].some((value) =>
    values.correspAccount?.includes(value),
  );

  return (
    <>
      <Modal
        closeHandler={handleClose}
        onDrag={true}
        customClass="G-cash-order-modal"
      >
        <div className="G-cash-order-content">
          <div className="G-modal-block-title">
            <h3>Դրամարկղի Մուտքի Օրդեր</h3>
          </div>

          <div className="G-cash-order-docNum-block G-flex">
            <DocNumFiled
              docTypeID={values.docTypeID}
              docDate={values.docDate}
              value={values.docNum}
              setFieldValue={setFieldValue}
              handleChangeDocNum={handleChange}
              addEvent={addEvent}
            />
            <div className="G-form-data-picker">
              <p className="G-title-p">Ամսաթիվ</p>
              <Calendar
                openToDate={new Date(values?.docDate)}
                value={dateFormat(values.docDate)}
                changeHandler={(date, e) => onChangeData(date, e)}
                name="docDate"
              />
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="G-flex-justify-between G-currency-account-flex">
              <div className="L-currency-box">
                <p className="G-title-p">Արժույթ</p>
                <span>{values.currencyCode}</span>
              </div>

              <div className="G-select-account-box">
                <SelectAccount
                  customClass="L-order-select-wrapper"
                  title="Դրամարկղի հաշիվ"
                  accountData={cashAccount}
                  accountVal={{
                    label: values.accountDebit,
                    value: values.accountDebit,
                  }}
                  selectChangeAccount={(values) =>
                    handleChangeDebitAccount(values)
                  }
                  disabled={cashAccount?.length > 1 ? false : true}
                />
                {touched.accountDebit && errors.accountDebit && (
                  <ErrorFiled error={errors.accountDebit} />
                )}
              </div>
            </div>

            <div className="G-flex-align-center">
              <SelectPartner
                partnerData={cashWarrantData?.partners}
                partnerVal={{
                  label: values.partnerName,
                  value: values.partnerName,
                }}
                selectChangePartner={(values) => handleChangePartner(values)}
              />
              <AddNew onClick={openPartnerAddForm} />
            </div>
            <div className="G-cash-warrant-recipient-input  G-relative">
              <InputFiled
                requiredFiled={true}
                inputTitle="Ստացված է (ումից)"
                name="recipient"
                value={values.recipient}
                changeHandler={handleChange}
                maxLength={50}
              />
              {touched.recipient && errors.recipient && (
                <ErrorFiled error={errors.recipient} />
              )}
            </div>

            <div className="G-cash-warrant-input">
              <SelectAccount
                customClass="L-order-select-wrapper"
                title="Թղթակցող հաշիվ"
                accountData={correspondentAccount}
                accountVal={{
                  label: values.correspAccount,
                  value: values.correspAccount,
                }}
                selectChangeAccount={(values) =>
                  handleChangeCorrespondentAccount(values)
                }
              />

              {touched.correspAccount && errors.correspAccount && (
                <ErrorFiled error={errors.correspAccount} />
              )}
            </div>
            <RegisterCalculationInReport
              values={values}
              setFieldValue={setFieldValue}
            />
            <div className="G-amount-input">
              <NumericFormatFiled
                value={values.amount}
                name="amount"
                onBlur={handleBlur}
                changeHandler={handleChange}
                inputTitle={
                  bankAccount
                    ? 'Գումարը'
                    : 'Գումարը (առավելագույնը 300 000 դրամ) '
                }
                requiredFiled={true}
              />

              {touched.amount && errors.amount && (
                <ErrorFiled error={errors.amount} />
              )}
            </div>

            <div className="G-cash-warrant-input  G-relative">
              <InputFiled
                inputTitle="Ստացման հիմքն ու նպատակը"
                name="docBase"
                value={values.docBase}
                changeHandler={handleChange}
                maxLength={textFiledLength}
              />
            </div>
            <div className="G-cash-warrant-input  G-relative">
              <InputFiled
                inputTitle="Կցվում են"
                name="areAttached"
                value={values.areAttached}
                changeHandler={handleChange}
                maxLength={textFiledLength}
              />
            </div>
            <GetSentDataBtn
              cancelPrintClick={handleClose}
              confirmExecuteClick={handleSubmit}
              executeClass={
                !isValid || !dirty || amountIsExceeded
                  ? 'G-disabled-button'
                  : ''
              }
              executeDisabled={!isValid || !dirty || amountIsExceeded}
            />
          </form>
        </div>
      </Modal>
      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={true}
          updatePartnerAction={updatePartnerAction}
          setPartnerInfo={newPartner}
          setPartnerTaxCode={setFieldValue}
        />
      )}
    </>
  );
};

export default CashWarrantForm;
