import React from 'react';
import AcceptOrCancelModal from '../../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../../App/Components/Alert';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';

const DeleteAssignPositionItem = ({ id, handleClose }) => {
  const { deleteAssignAdditional, errorMess, setErrorMess } =
    useAssignAdditionalStorage();

  const onRemoveHandler = async () => {
    try {
      await deleteAssignAdditional(id, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };
  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />

      {errorMess && (
        <Alert description={errorMess} onClose={handleAlertClose} />
      )}
    </>
  );
};

export default DeleteAssignPositionItem;
