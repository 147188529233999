import React from 'react';
import { NumericFormat } from 'react-number-format';
import './style.scss';
import { onFocusRemoveZero } from '../../Utilities/Utilities';

const NumericFormatFiled = ({
  value,
  name,
  changeHandler,
  onBlur,
  requiredFiled,
  inputTitle,
  allowNegative = false,
  disabled,
  customClass = '',
  style,
  maxLength = 18,
}) => {
  return (
    <div
      className={`G-numeric-format-filed ${customClass && 'input-has-border'}`}
    >
      <div className="G-input-title-flex G-flex">
        {requiredFiled && <span className="G-asterisk">*</span>}
        {inputTitle && <p className="G-title-p">{inputTitle}</p>}
      </div>
      <NumericFormat
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={changeHandler}
        allowLeadingZeros
        thousandSeparator=","
        inputMode="numeric"
        allowNegative={allowNegative}
        decimalScale={2}
        disabled={disabled}
        fixedDecimalScale
        style={style}
        autocomplete="off"
        onFocus={(e) => onFocusRemoveZero(e)}
        maxLength={maxLength}
        // decimalSeparator={','}
        //decimalSeparator={'$'}
      />
    </div>
  );
};

export default NumericFormatFiled;
