import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Button from '../../../App/Components/Button/Button';
import { dateFormat } from '../../../App/Utilities/Utilities';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import useMakeAdditionalSave from '../../hooks/useMakeAdditionalSave';
import {
  handleChangeHours,
  handleChangeCoefficient,
  onAmountChange,
  handleCheckboxChange,
} from './makeAdditionalSaveHandlers';
import Loading from '../../../App/Components/Loading/Loading';
const MakeAdditionalSaveTable = ({ mainData, query, resetQuery }) => {
  const { addMakeAddition, isLoading } = useMakeAdditionalSave();
  const [newMakeAnAddition, setNewMakeAnAddition] = useState(mainData);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkSign, setCheckSign] = useState(false);
  useEffect(() => {
    setNewMakeAnAddition(mainData);
  }, [mainData]);

  useEffect(() => {
    const checkEveryItem = newMakeAnAddition?.every((item) => item.sign);
    setCheckSign(checkEveryItem);
  }, [newMakeAnAddition]);

  const columnConfig = [
    {
      title: 'Հ/Պ կոդը',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Հավելում / պահում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 550, overflow: 'hidden' },
    },
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.calcDate),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Ժամ',
      cell: (row) => (
        <input
          type="number"
          value={row?.hours}
          onChange={(e) =>
            handleChangeHours(
              e,
              row,
              newMakeAnAddition,
              setNewMakeAnAddition,
              setIsDisabled,
            )
          }
          disabled={!(row?.formulaID === 2 || row.formulaID === 4)}
          maxLength={4}
        />
      ),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Գործակիցը',
      cell: (row) => (
        <input
          type="number"
          value={row?.coeff}
          onChange={(e) =>
            handleChangeCoefficient(
              e,
              row,
              newMakeAnAddition,
              setNewMakeAnAddition,
              setIsDisabled,
            )
          }
          disabled={
            !(
              row?.formulaID === 2 ||
              row.formulaID === 4 ||
              row.formulaID === 5 ||
              row.formulaID === 1
            )
          }
          maxLength={5}
          pattern="[0-9.,]*"
        />
      ),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Գումար',
      cell: (row) => (
        <NumericFormatFiled
          value={row.amount}
          allowNegative={true}
          name="amount"
          changeHandler={(e) =>
            onAmountChange(
              e,
              row,
              newMakeAnAddition,
              setNewMakeAnAddition,
              setIsDisabled,
            )
          }
          inputTitle=""
          disabled={!(row.formulaID === 5 || row.formulaID === 1)}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(
                e,
                null,
                newMakeAnAddition,
                setNewMakeAnAddition,
                setIsDisabled,
              )
            }
            checked={checkSign}
          />
        </span>
      ),

      cell: (row) => (
        <Checkbox
          checked={row.hours > 0 || row.amount ? true : row.sign}
          value={row.hours > 0 || row.amount ? true : row.sign}
          onChange={(e) =>
            handleCheckboxChange(
              e,
              row,
              newMakeAnAddition,
              setNewMakeAnAddition,
              setIsDisabled,
            )
          }
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];
  const onSaveHandler = async () => {
    try {
      await addMakeAddition(newMakeAnAddition);

      setIsDisabled(true);
      resetQuery();
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {newMakeAnAddition !== null && newMakeAnAddition?.length ? (
        <Table
          customClass="L-make-additional-save-table"
          data={newMakeAnAddition}
          columnConfig={columnConfig}
        />
      ) : null}
      {newMakeAnAddition !== null && newMakeAnAddition?.length && (
        <div className="G-confirm-btn-wrap">
          <Button
            onClick={onSaveHandler}
            disabled={isDisabled}
            text="Հաստատել"
            type="submit"
            customClass={isDisabled && 'G-disabled-button'}
          />
        </div>
      )}
    </>
  );
};

export default MakeAdditionalSaveTable;
