import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Button from '../../../App/Components/Button/Button';
import { removeCommas } from '../../../App/Utilities/Utilities';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import BenefitAveragesTotal from './BenefitAveragesTotal';

const BenefitCalculationAverages = ({ mainData, saveAverages }) => {
  const { averagesTotal, averages } = mainData || {};
  const [newBenefitAverages, setNewBenefitAverages] = useState(averages);

  const [newTotalAmount, setNewTotalAmount] = useState({
    totalAmount: averagesTotal?.totalAmount,
    totalAm12: averagesTotal?.totalAm12,
    totalCount: averagesTotal?.totalCount,
  });

  useEffect(() => {
    setNewBenefitAverages(mainData?.averages);
    setNewTotalAmount(mainData?.averagesTotal);
  }, [mainData?.averages, mainData?.averagesTotal]);

  const calculateTotalAmount = (averages) => {
    const initialTotals = { total: 0, totalAm12: 0, totalCount: 0 };

    const updatedTotals = averages?.reduce((totals, item) => {
      if (item.sign === 1) {
        totals.total += item.amount;
        totals.totalAm12 += item.am12;
        totals.totalCount++;
      }
      return totals;
    }, initialTotals);

    setNewTotalAmount({
      totalAmount: updatedTotals.total,
      totalAm12: updatedTotals.totalAm12,
      totalCount: updatedTotals.totalCount,
    });
  };

  const onInputChange = (e, row) => {
    const { name, value } = e.target;
    const newValue = name === 'amount' ? removeCommas(value) : Number(value);

    const updatedRows = newBenefitAverages?.map((r) => {
      if (r.averageID === row.averageID) {
        return { ...r, [name]: newValue, isModifed: true };
      } else {
        return r;
      }
    });

    setNewBenefitAverages(updatedRows);
  };

  const onMarkCurrentCheckbox = (e, row) => {
    const { checked } = e.target;

    let updatedList = newBenefitAverages.map((item) => {
      if (item.averageID === row.averageID) {
        return { ...item, sign: checked ? 1 : 0, isModifed: checked };
      }

      return item;
    });
    calculateTotalAmount(updatedList);
    setNewBenefitAverages(updatedList);
  };

  const columnConfig = [
    {
      title: 'Ամիսը',
      cell: (row) => row.stMonth,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Օրաց. օր',
      cell: (row) => (
        <input
          type="number"
          value={row?.workDays}
          name="workDays"
          onChange={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
          maxLength={2}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Փաստ. օր',
      cell: (row) => (
        <input
          type="number"
          value={row?.days}
          name="days"
          onChange={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Միջին աշխատ.',

      cell: (row) => (
        <NumericFormatFiled
          value={row.amount}
          allowNegative={true}
          name="amount"
          changeHandler={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
          maxLength={3}
          inputTitle=""
        />
      ),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Նշ',
      cell: (row) => (
        <Checkbox
          checked={row.sign}
          value={row.sign}
          onChange={(e) => onMarkCurrentCheckbox(e, row)}
          disabled={row.signAvg !== 1}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];
  const onSaveHandler = async () => {
    const values = {
      empNo: mainData.empNo,
      vacStartDate: mainData.vacStartDate,
      avgDays: mainData.avgDays,
    };
    try {
      await saveAverages(newBenefitAverages, values);
      // setIsDisabled(true);
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };

  return (
    <>
      {newBenefitAverages !== null && newBenefitAverages?.length ? (
        <Table
          customClass="L-vacation-averages-table G-table-has-scroll"
          data={newBenefitAverages}
          columnConfig={columnConfig}
        />
      ) : null}
      <BenefitAveragesTotal mainData={newTotalAmount} />
      {newBenefitAverages !== null && newBenefitAverages?.length && (
        <div className="G-confirm-btn-wrap">
          <Button
            onClick={onSaveHandler}
            //disabled={isDisabled}
            text="Հաստատել"
            type="submit"
            //customClass={isDisabled && 'G-disabled-button'}
          />
        </div>
      )}
    </>
  );
};

export default BenefitCalculationAverages;
