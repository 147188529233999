import * as Yup from 'yup';
import {
  EnterNumberOnly,
  SelectFieldMessage,
  taxCodeMessage,
} from './ErrorMessage';

const fieldIsRequired = 'Այս դաշտը պարտադիր է';

const CustomTaxCodePattern = /^[a-zA-Z0-9_.-]*$/;

export const PartnerFormValidation = () => {
  return Yup.object()
    .shape({
      partnerName: Yup.string().required(fieldIsRequired),
      partnerType: Yup.string().required(SelectFieldMessage),
      taxCode: Yup.string().when(
        ['countryType', 'partnerForm', 'partnerType'],
        (countryType, partnerForm, partnerType) => {
          if (partnerForm === false && countryType === 0 && partnerType != 4) {
            return Yup.string()
              .matches(/^[0-9]+$/, EnterNumberOnly)
              .length(8, taxCodeMessage)
              .required(fieldIsRequired);
          }
          if (partnerForm === false && countryType === 1 && partnerType != 4) {
            return Yup.string()
              .matches(CustomTaxCodePattern, 'Invalid custom tax code')
              .length(20, '20 Նիշ')
              .required(fieldIsRequired);
          }
        },
      ),

      taxeServiceNumber: Yup.string().when(
        ['partnerForm', 'countryType', 'partnerType'],
        {
          is: (partnerForm, countryType, partnerType) =>
            (partnerForm && countryType === 0) || partnerType == 4,
          then: Yup.string()
            .matches(/^\d*(\.\d+)?$/, EnterNumberOnly)
            .length(10, '10 Նիշ'),
        },
      ),

      passport: Yup.string().when(['partnerForm', 'countryType'], {
        is: (partnerForm, countryType) => partnerForm && countryType === 0,
        then: Yup.string()
          .matches(/^[A-Z]{2}\d{7}$/, 'Սխալ')
          .length(9, '9 Նիշ'),
      }),

      idCard: Yup.string().when(['partnerForm', 'countryType'], {
        is: (partnerForm, countryType) => partnerForm && countryType === 0,
        then: Yup.string()
          .matches(/^[0-9]+$/, EnterNumberOnly)
          .length(9, '9 Նիշ'),
      }),

      partnerForm: Yup.boolean(),
    })
    .test('at-least-one-required', null, function (value) {
      const { partnerForm, countryType, partnerType } = value;

      if (!partnerForm) {
        const { taxeServiceNumber } = value;
        if (partnerType == 4 && !taxeServiceNumber) {
          return this.createError({
            path: 'taxeServiceNumber',
            message: fieldIsRequired,
          });
        }
      } else if (partnerForm && countryType === 0) {
        const { taxeServiceNumber, passport, idCard } = value;
        if (!taxeServiceNumber && !passport && !idCard) {
          return this.createError({
            path: 'all',
            message: 'Լրացնել առնվազն մեկ տվյալ',
          });
        }
      }
    });
};







