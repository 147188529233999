import { useEffect, useState } from 'react';
import { postRequest } from '../Api/Api';

const useVerify = (captchaValue) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(null);

  const verify = async () => {
    setIsLoading(true);

    try {
      const captchaResponse = await postRequest('verifyCaptcha', {
        captchaValue,
      });
      const captchaResult = captchaResponse.data.success;

      setIsVerify(captchaResult);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (captchaValue) {
      verify();
    }
  }, [captchaValue]);

  return { isVerify };
};

export default useVerify;
