import React, { useState } from "react";
import { getRequest } from "../../Api/Api";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Components/Header/Header';
import {
  dataTimeFormat,
  initialDate,
  printHandler,
} from '../../Utilities/Utilities';
import AccountAnalysisTable from './AccountAnalysisTable';
import TAccountBalance from '../../Components/TAccountBalance/TAccountBalance';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import AccountAnalysisAction from './AccountAnalysisAction';
import './index.scss';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';

const GetAccountAnalysisData = () => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);

  const [accountVal, setAccountVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });

  const [accountAnalysisData, setAccountAnalysisData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
 const { data } = useFetchCurrency();
  const dateUrl = `dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`;
  const generalUrl = `companyID=${currentCompanyID}&account=${
    accountVal.value
  }&currencyID=${
    currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
  }&${dateUrl}`;
  const dispatch = useDispatch();
  const getData = () => {
    setLoading(true);
    getRequest(`gettaccountanalisis?${generalUrl}`)
      .then((res) => {
        setAccountAnalysisData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrint = () => {
    printHandler(`printtaccountanalisis?${generalUrl}`, dispatch);
  };
  const executeHandler = () => {
    getData();
  };

  return (
    <>
      <Header pageTitle="T – Հաշիվ (հաշվի վերլուծություն)" headerBtn={false} />

      <SearchBackground>
        <AccountAnalysisAction
          printHandler={handlePrint}
          executeHandler={executeHandler}
          accountVal={accountVal}
          setAccountVal={setAccountVal}
          dateStart={dateStart}
          setDateStart={setDateStart}
          setDateEnd={setDateEnd}
          dateEnd={dateEnd}
          setAccountStatementData={accountAnalysisData}
          currencyVal={currencyVal}
          setCurrencyVal={setCurrencyVal}
          accountAnalysisData={accountAnalysisData}
        />
      </SearchBackground>

      <TAccountBalance mainData={accountAnalysisData} loading={loading}>
        <AccountAnalysisTable
          mainData={accountAnalysisData?.entries}
          loading={loading}
        />
      </TAccountBalance>
    </>
  );
};

export default GetAccountAnalysisData;
