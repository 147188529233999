import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from "../../Api/Api";
import Header from "../../Components/Header/Header";
import SearchBackground from "../../Components/SearchForm/SearchBackground";
import {
  dataTimeFormat,
  initialDate,
  MAIN_URL,
  printHandler,
} from '../../Utilities/Utilities';
import SettlementDocumentAction from './SettlementDocumentAction';
import SettlementDocumentTable from './SettlementDocumentTable';
import './index.scss';
import ConvertDataExcel from './ConvertDataExcel';

const GetSettlementDocument = () => {
  const { currentCompanyID, currentCompanyName, startDate } = useSelector(
    (state) => state.auth,
  );
  const [settlementDocumentsData, setSettlementDocumentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const dispatch = useDispatch();
  const [partnerTypeVal, setPartnerTypeVal] = useState({
    label: '',
    value: '',
    id: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    getRequest(
      `getExcelInvoiceBook?companyID=${currentCompanyID}&partnerType=${partnerTypeVal.id}&dateStart=${dateStartFormat}&DateEnd=${dateEndFormat} `,
    )
      .then((res) => {
        setSettlementDocumentsData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentCompanyID, dateEndFormat, dateStartFormat, partnerTypeVal.id]);

  const handlePrint = (id) => {
    printHandler(
      `printExcelInvoiceBook?companyID=${currentCompanyID}&companyName=${currentCompanyName}&partnerType=${partnerTypeVal?.id}&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`,
      dispatch,
    );
  };

    const dataForExcel = ConvertDataExcel(settlementDocumentsData?.invoices);
    return (
      <>
        <Header
          headerBtn={false}
          pageTitle="Հաշվարկային փաստաթղթերի մատյան (e-invoicing)"
        />
        <SearchBackground>
          <SettlementDocumentAction
            partnerTypeVal={partnerTypeVal}
            setPartnerTypeVal={setPartnerTypeVal}
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            viewPrintHandler={handlePrint}
            dataForExcel={dataForExcel}
          />
        </SearchBackground>
        <SettlementDocumentTable
          mainData={settlementDocumentsData?.invoices}
          loading={isLoading}
        />
      </>
    );
};

export default GetSettlementDocument;
