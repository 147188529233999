import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../Api/Api";
import Button from "../../Components/Button/Button";
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import SearchBar from '../../Components/SearchForm/SearchBar';
import {
  getProductAction,
  loadingAction,
} from '../../Redux/productSlices/useAction';
import { printHandler, printText } from '../../Utilities/Utilities';
import EventAddForm from './EventAddForm';
import ProductTable from './ProductTable';

const GetProductData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { productData, isLoading } = useSelector(
    (state) => state.getProductData,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const dispatch = useDispatch();


  const getData = async (pageNumber, searchValue = '') => {
    try {
      dispatch(loadingAction(true));

      const response = await getRequest(
        `getProductNames?companyID=${currentCompanyID}&pageNumber=${
          pageNumber + 1
        }&searchstring=${searchValue.trim()}`,
      );

      dispatch(getProductAction(response.data));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      dispatch(loadingAction(false));
    }
  };

  useEffect(() => {
    getData(pageNumber, searchValue);
  }, [pageNumber, currentCompanyID, dispatch]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  const handlePrint = (id) => {
    printHandler(`printProductNames?companyID=${currentCompanyID}`, dispatch);
  };

  const filteredDateForExcel =
    productData &&
    productData?.productNames?.map((i) => {
      return {
        'Հ/Հ': i.sequenceNumber,
        'Ապրանքի անվանումը': i.productName,
      };
    });

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Ապրանքներ" handelClick={openAddForm} />
      <SearchBackground>
        <div className="G-flex">
          <SearchBar
            getData={getData}
            pageNumber={pageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setPageNumber={setPageNumber}
            name="searchstring"
          />
          <div className="G-view-btn">
            <Button text={printText} onClick={handlePrint} />
          </div>
          <ExportExcel
            excelData={filteredDateForExcel}
            fileName={'Ապրանքներ'}
          />
        </div>
      </SearchBackground>
      <ProductTable mainData={productData?.productNames} loading={isLoading} />
      <Pagination
        pageCount={productData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openAddModal && (
        <EventAddForm
          closeModal={setOpenAddModal}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
    </div>
  );
};

export default GetProductData;
