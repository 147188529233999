import React, { useState } from 'react';
import Calendar from './Calendar/Calendar';
import SelectionOptions from './SelectionOptions/SelectionOptions';
import OtherCalendars from './OtherCalendars/OtherCalendars';
import './FullCalendar.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCalendarAction,
  getDayNoteAction,
  loadingAction,
  notLoadingAction,
} from '../App/Redux/calendarSlices/useAction';
import { getRequest } from '../App/Api/Api';
import { useEffect } from 'react';
import FullCalendarHeader from './FullCalendarHeader/FullCalendarHeader';
import FilterArea from './FilterArea/FilterArea';

import DayNotifications from './DayNotifications/DayNotifications';
import { dataTimeFormat } from '../App/Utilities/Utilities';

const FullCalendar = () => {
  const { userID } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState('');

  const buildUrl = () => {
    const params = {
      userId: userID,
    };

    if (
      selectedOptions &&
      selectedOptions.length > 0 &&
      !selectedOptions.includes(0)
    ) {
      params.colorString = selectedOptions;
    }

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return `getUserCalendar?${queryString}`;
  };

  const fetchCalendarData = () => {
    dispatch(loadingAction(true));

    const url = buildUrl();

    getRequest(url)
      .then((res) => {
        dispatch(getCalendarAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    fetchCalendarData();
  }, [userID, selectedOptions]);

  const fetchCurrentDayData = async () => {
    dispatch(notLoadingAction(true));

    try {
      const response = await getRequest(
        `getDayCalendar?userId=${userID}&date=${dataTimeFormat(new Date())}`,
      );

      if (response.data) {
        dispatch(getDayNoteAction(response.data));
      } else {
        console.error('Failed to fetch data:', response);
      }
    } catch (error) {
      console.error('Error during data fetch:', error);
    } finally {
      dispatch(notLoadingAction(false));
    }
  };

  useEffect(() => {
    fetchCurrentDayData();

    return () => {};
  }, [dispatch, userID]);

  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    <div className="full-calendar-wrap">
      <FullCalendarHeader />

      <div className="L-full-calendar-block G-flex">
        <div className="full-calendar-wrap-left-panel">
          <OtherCalendars
            fetchCalendarData={fetchCalendarData}
            fetchCurrentDayData={fetchCurrentDayData}
          />
          <SelectionOptions
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />

          <DayNotifications />
        </div>

        <div className="full-calendar-wrap-center-panel">
          <Calendar fetchCalendarData={fetchCalendarData} />
        </div>

        <div className="full-calendar-wrap-right-panel">
          <FilterArea colorString={selectedOptions} />
        </div>
      </div>
    </div>
  );
};

export default FullCalendar;
