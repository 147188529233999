import reports from '../assets/menuIcons/reports.svg';
import informants from '../assets/menuIcons/Informants.svg';
import dashboard from '../assets/menuIcons/dashboard.svg';
export const routing = [
  {
    path: '/EmployeesDashboard',
    name: '',
    icon: dashboard,
  },

  {
    path: '#',
    name: 'Տեղեկատուներ',
    icon: informants,
    chillers: [
      {
        path: '/Subdivisions',
        name: 'Ստորաբաժանումներ',
      },
      {
        path: '/Positions',
        name: 'Պաշտոններ',
      },

      {
        path: '/Employees',
        name: 'Աշխատակիցներ',
      },
      {
        path: '/Non_working_days',
        name: 'Ոչ աշխատանքային օրեր',
      },
      {
        path: '/Working_time_tables',
        name: 'Աշխատաժամանակի տաբելներ',
      },

      {
        path: '/Additions',
        name: 'Հավելումներ',
      },
      {
        path: '/Savings',
        name: 'Պահումներ',
      },
      {
        path: '/Provisions',
        name: 'Դրույթներ',
      },
    ],
  },
  {
    path: '#',
    name: 'Հաշվարկներ',
    icon: reports,
    chillers: [
      {
        path: '/Actions_with_employees',
        name: 'Գործողություններ աշխատակիցների հետ',
      },
      {
        path: '/Assign_positions',
        name: 'Նշանակել հաստիքներ',
      },

      {
        path: '/Confirm_positions',
        name: 'Հաստատել հաստիքները',
      },
      {
        path: '/Make_an_addition',
        name: 'Կատարել հավելում, պահում',
      },
      {
        path: '/Salary_calculation',
        name: 'Աշխատավարձի հաշվարկ',
      },

      {
        path: '/Accounting_formulations',
        name: 'Հաշվապահական ձևակերպումներ',
      },
    ],
  },
  {
    path: '#',
    name: 'Փաստաթղթեր',
    icon: reports,
    chillers: [
      {
        path: '/Bank_paymentLists',
        name: 'Բանկի վճարացուցակներ',
      },
      {
        path: '/Calculation_data_viewing',
        name: 'Հաշվարկային տվյալների դիտում',
      },
      {
        path: '/Calculation_sheets_viewing',
        name: 'Հաշվարկային թերթիկների դիտում',
      },
      {
        path: '/Summary_data',
        name: 'Ամփոփ տվյալներ',
      },
      {
        path: '/General_summary',
        name: 'Ընդհանուր ամփոփագիր',
      },
    ],
  },
];
