import { dateFormat } from '../../Utilities/Utilities';

export const ConvertDataExcel = (opertionData) => {
  return (
    opertionData &&
    opertionData?.map((i) => {
      return {
        Ամսաթիվ: dateFormat(i.docDate),
        'Փաստաթղթի Տեսակը': i.docTypeName,
        'Փաստաթուղթ N': i.docNum,
        Գործընկեր: i.partnerName,
        Արժույթ: i.currencyCode,
        'Դեբետ հաշիվ': i.accountDebit,
        Արժույթ: i.credCurrCode,
        'Կրեդիտ հաշիվ': i.accountCredit,
        Փոխարժեք: i.exchangeRate,
        Գումարը: i.amount,
        'Գումար AMD': i.amountAMD,
      };
    })
  );
};

export default ConvertDataExcel;
