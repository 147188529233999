import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HelperTextArr } from '../HelperText/HelperText';
import './table.scss';

const Table = ({ columnConfig = [], data, customClass = '' }) => {
  const [isActive, setIsActive] = useState(null);
  const initBalanceId = localStorage.getItem('initBalanceId');
  const initialValue = JSON.parse(initBalanceId);

  setTimeout(() => {
    localStorage.removeItem('initBalanceId');
  }, 5000);

  const onClick = (index) => {
    setIsActive(index);
  };

  let location = useLocation();

  const helpHandler = () => {
    //for feather
    const foundItem = HelperTextArr?.find(
      ({ path }) => path === location.pathname,
    );
    return foundItem ? foundItem.mes : '';
  };

  return (
    <>
      {data ? (
        <table className={`G-table-block ${customClass}`}>
          <thead className="G-table-header">
            <tr className="G-table-header-row">
              {columnConfig?.map((item, index) => {
                return (
                  <th
                    className="G-table-header-cell"
                    key={index}
                    style={item?.customStyle}
                  >
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="G-table-body">
            {data?.map((items, index) => {
              return (
                <tr
                  key={index}
                  className={`G-table-body-row ${
                    (items?.rowClass || '') && items?.rowClass
                  } ${
                    (items.accountCode &&
                      items.accountCode.includes(initialValue)) ||
                    isActive === index
                      ? 'G-active-table'
                      : ''
                  }`}
                  onClick={() => onClick(index)}
                >
                  {columnConfig?.map((columns, indexColumn) => {
                    const title = columns?.cell(items);
                    return (
                      <td
                        title={title && typeof title === 'object' ? '' : title}
                        className="G-table-body-cell"
                        key={indexColumn}
                        style={columns.customStyle}
                      >
                        {columns?.cell(items)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        helpHandler()
      )}
    </>
  );
};

export default Table;
