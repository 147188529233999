import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../Api/Api';
import ReCAPTCHA from 'react-google-recaptcha';
import InputFiled from '../../Components/InputFiled/InputFiled';
import { AuthLoginValidation } from '../../Validation/AuthValidation';
import Button from '../../Components/Button/Button';
import { ReactComponent as Lock } from '../../Assets/icons/lock.svg';
import { ReactComponent as Epistle } from '../../Assets/icons/epistle.svg';
import { ReactComponent as CloseEye } from '../../Assets/icons/eye-close.svg';
import { ReactComponent as ShowEye } from '../../Assets/icons/eye-show.svg';
import { authLogin } from '../../Redux/authSlices/useAction';
import { useHistory } from 'react-router-dom';
import ForgotPassword from '../../Elements/ForgotPassword/ForgotPassword';
import Alert from '../../Components/Alert';
import Spinner from '../../Components/Spinner/Spinner';
import { mainPage } from '../../Utilities/Utilities';
import useVerify from '../../Hooks/useVerify';
import OneWindowLogo from '../../Components/OneWindowLogo/OneWindowLogo';
import useEmailExistenceCheck from '../../Hooks/useEmailExistenceCheck';
import { useDebounce } from '../../Hooks/useDebounce';

const Login = ({ togglePage, onToggleTab }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorHandler, setErrorHandler] = useState('');
  const [openResetPas, setOpenResetPas] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const reCaptchaRef = useRef();

  const REACT_APP_SITE_KEY = '6LeiHZ8pAAAAAICL3RbFtET55dyNtED0nxqsWJQI';
  const { isVerify } = useVerify(captchaValue);
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: AuthLoginValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });
  const debouncedEmail = useDebounce(values.email, 1000);
  const { isEmailExist, error, setError } =
    useEmailExistenceCheck(debouncedEmail);
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const forgotPasswordHandler = () => {
    setOpenResetPas(true);
  };

  const onSubmit = async (values, resetForm) => {
    const captchaToken = await reCaptchaRef.current.executeAsync();
    reCaptchaRef.current.reset();
    setCaptchaValue(captchaToken);

    postRequest('loginApp', values).then((res) => {
      const { errorCode, message } = res.data;
      if (errorCode !== 0 && message !== '') {
        setErrorHandler(message);
        setIsSubmitting(false);
        return;
      } else if (errorCode === 0) {
        dispatch(authLogin(res.data));
        resetForm();
        setIsSubmitting(false);
        history.push(mainPage);
      }
    });
  };

  return (
    <div className="G-login-register-main-form" style={{ padding: '36px' }}>
      {/* <div className="G-log-reg-form-header">
        <OneWindowLogo handleClick={onToggleTab} />
      </div> */}
      <div className="G-log-reg-container">
        <form onSubmit={handleSubmit}>
          <div className="G-log-reg-input-wrapper">
            <InputFiled
              name="email"
              value={values.email}
              changeHandler={handleChange}
              placeholder="Էլ.հասցե"
              customClass={`L-input-has-border ${
                touched.email && errors.email
                  ? 'G-invalid-input'
                  : 'G-valid-input'
              }`}
            />

            <span className="L-input-icon">
              <Epistle />
            </span>
          </div>

          <div className="G-log-reg-input-wrapper">
            <InputFiled
              name="password"
              type={passwordShown ? 'text' : 'password'}
              value={values.password}
              onBlur={handleBlur}
              changeHandler={handleChange}
              placeholder="Գաղտնաբառ"
              customClass={`L-input-has-border ${
                touched.password && errors.password
                  ? 'G-invalid-input'
                  : 'G-valid-input'
              }`}
              //disabled={!isEmailExist}
            />

            <span className="L-input-icon">
              <Lock />
            </span>
            <span className="L-toggle-icon" onClick={togglePasswordVisibility}>
              {passwordShown ? <ShowEye /> : <CloseEye />}
            </span>
          </div>

          <div className="L-log-reg-remember-password G-flex-justify-end">
            <button
              disabled={!values?.email?.length}
              onClick={forgotPasswordHandler}
            >
              Մոռացե՞լ եք գաղտնաբառը
            </button>
          </div>

          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={REACT_APP_SITE_KEY}
            size="invisible"
          />
          <div className="G-log-reg-btn">
            <Button
              onClick={handleSubmit}
              type="submit"
              customClass={
                !isValid || isSubmitting || isVerify ? 'G-disabled-button' : ''
              }
              disabled={!isValid || isSubmitting || isVerify}
              text={isSubmitting ? <Spinner /> : 'Մուտք'}
            />
          </div>
          {/* <div className="L-do-not-account  G-flex-justify-between">
            <p>Դեռ գրանցված չե՞ք</p>
            <strong onClick={() => togglePage('Register')}>
              Ստեղծեք Ձեր էջը
            </strong>
          </div> */}
        </form>
      </div>

      <Alert description={errorHandler} onClose={setErrorHandler} />
      {openResetPas && (
        <ForgotPassword
          closeModal={setOpenResetPas}
          showError={setErrorHandler}
          setFieldValue={setFieldValue}
        />
      )}
    </div>
  );
};

export default Login;
