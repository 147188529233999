import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from '../../Api/Api';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import SearchBar from '../../Components/SearchForm/SearchBar';
import { loadingAction } from '../../Redux/serviceWork/useAction';
import { getUnitOfMeasureAction } from '../../Redux/unitOfMeasureSlices/useAction';

import EventAddForm from './EventAddForm';
import UnitOfMeasureTable from './UnitOfMeasureTable';

const GetUnitOfMeasureData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { unitOfMeasureData, isLoading } = useSelector(
    (state) => state.getUnitOfMeasureData,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const dispatch = useDispatch();

  const getData = (pageNumber, searchValue = '') => {
    dispatch(loadingAction(true));
    getRequest(
      `getunits?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&searchstring=${searchValue.trim()}`,
    )
      .then((res) => {
        dispatch(getUnitOfMeasureAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber, searchValue);
  }, [pageNumber, dispatch]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  const filteredDateForExcel =
    unitOfMeasureData &&
    unitOfMeasureData?.units?.map((i) => {
      return {
        'Հ/Հ': i.sequenceNumber,
        'Կարճ անվանումը': i.unitCode,
        Անվանումը: i.unitName,
      };
    });

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Չափման միավորներ" handelClick={openAddForm} />
      <SearchBackground>
        <div className="G-flex">
          <SearchBar
            getData={getData}
            pageNumber={pageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setPageNumber={setPageNumber}
            name="searchstring"
          />

          <ExportExcel
            excelData={filteredDateForExcel}
            fileName="Չափման միավորներ"
          />
        </div>
      </SearchBackground>
      <UnitOfMeasureTable
        mainData={unitOfMeasureData?.units}
        loading={isLoading}
      />
      <Pagination
        pageCount={unitOfMeasureData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
      {openAddModal && (
        <EventAddForm
          closeModal={setOpenAddModal}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
    </div>
  );
};

export default GetUnitOfMeasureData;
