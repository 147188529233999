import { useEffect, useState } from 'react';
import {
  executeCalculationSheets,
  fetchCalculationSheetMonth,
  fetchCalculationSheets,
} from '../services/fetchCalculationSheets';
import { useSelector } from 'react-redux';

const useCalculationSheets = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [calculationSheetData, setCalculationSheetData] = useState(null);
  const [calculationTableData, setCalculationTableData] = useState(null);
  const getCalculationSheets = async () => {
    setIsLoading(true);
    try {
      const data = await fetchCalculationSheets(currentCompanyID);
      setCalculationSheetData(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCalculationSheetMonth = async (month) => {
    setIsLoading(true);
    try {
      const data = await fetchCalculationSheetMonth(currentCompanyID, month);

      if (data?.salaryMonths) {
        setCalculationSheetData((prevFormData) => ({
          ...prevFormData,
          salaryMonths: [...data?.salaryMonths],
        }));
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExecuteCalculationSheet = async (query) => {
    setIsLoading(true);
    try {
      const data = await executeCalculationSheets(currentCompanyID, query);
      setCalculationTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getCalculationSheets,
    calculationSheetData,
    getCalculationSheetMonth,
    isLoading,
    error,
    errorMess,
    setErrorMess,
    getExecuteCalculationSheet,
    calculationTableData,
    setCalculationTableData,
  };
};

export default useCalculationSheets;
