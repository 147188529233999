import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Calendar from '../../Components/Calendar/Calendar';
import Modal from '../../Components/Modal/Modal';
import InputFiled from '../../Components/InputFiled/InputFiled';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import RadioButton from '../../Components/RadioButton/RadioButton';
import SelectPartner from '../../Components/SelectBox/SelectPartner';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { useFormik } from 'formik';
import { getRequest } from '../../Api/Api';
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import {
  Commas,
  dataTimeFormat,
  dateFormat,
  GetAccountCurrencies,
  removeCommas,
  textFiledLength,
} from '../../Utilities/Utilities';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import { OperationsValidation } from '../../Validation/OperationsValidation';
import { default as PartnerForm } from '../../Pages/Partners/EventAddForm';
import AddNew from '../../Elements/Add/AddNew';
import { updatePartnerAction } from '../../Redux/operationsSlices/useAction';

import ExchangeRateFiled from '../../Components/ExchangeRateFiled/ExchangeRateFiled';
import DocNumFiled from '../../Components/DocNumFiled';
import Checkbox from '../../Components/Checkbox/Checkbox';
import { AllowedAmountAmd } from './AllowedAmountAmd';

const OperationsForm = memo(
  ({
    closeModal,
    onSubmit,
    rowData,
    activeIsCopy = false,
    addEvent = false,
    entryIDForAdd = null,
  }) => {
    const { exchangeRate, entryID, docDate } = rowData || {};
    const { currentCompanyID } = useSelector((state) => state.auth);
    const { operationsData } = useSelector((state) => state.getOperationsData);
    const [firstAccountData, setFirstAccountData] = useState([]);
    const [secondAccountData, setSecondAccountData] = useState([]);
    const [operationsFormData, setOperationsFormData] = useState([]);
    const [showPartnerForm, setShowPartnerForm] = useState(false);

    const [initAmount, setInitAmount] = useState(0);
    const [firstCurrencies, setFirstCurrencies] = useState([]);
    const [secondCurrencies, setSecondCurrencies] = useState([]);
    useEffect(() => {
      getRequest(
        `getEntryForEdit?companyID=${currentCompanyID}&entryID=${
          entryIDForAdd == null ? entryID : entryIDForAdd
        }`,
      )
        .then((res) => {
          setOperationsFormData(res?.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });

      if (activeIsCopy && entryID) {
        getRequest(
          `getEntryForCopy?companyID=${currentCompanyID}&entryID=${entryID}`,
        )
          .then((res) => {
            setOperationsFormData(res.data);
          })
          .catch((Err) => {
            console.log('Error', Err);
          });
      }
    }, [activeIsCopy, currentCompanyID, entryID]);

    const {
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      handleBlur,
      setFieldValue,
      isValid,
      dirty,
    } = useFormik({
      mode: 'onBlur',
      enableReinitialize: true,
      initialValues: {
        ...operationsFormData,
      },
      validationSchema: OperationsValidation,
      onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
    });

    const generalExchangeRate = removeCommas(values.exchangeRate);
    const currentAccountCode =
      values?.debitCredit === 1 ? values.secondAccount : values.firstAccount;

    const currentCurrency =
      values?.debitCredit === 1 ? values.credCurrID : values.currencyID;
    const creditAccountKeywords = ['251', '252', '253'];
    const checkCreditAccount = creditAccountKeywords.some((keyword) =>
      currentAccountCode?.includes(keyword),
    );

    useEffect(() => {
      if (checkCreditAccount && currentCurrency) {
        getRequest(
          `getAccountBalance?companyID=${currentCompanyID}&account=${currentAccountCode}&currencyID=${currentCurrency}&dateEnd=${dataTimeFormat(
            values.docDate,
          )}`,
        )
          .then((res) => {
            setInitAmount(res.data);
          })
          .catch((err) => {
            console.log('Err' + err);
          });
      } else {
        setInitAmount(0);
      }
    }, [
      checkCreditAccount,
      currentAccountCode,
      currentCompanyID,
      currentCurrency,
      values.docDate,
    ]);

    useEffect(() => {
      const fetchAccountCurrencies = async (
        accountCode,
        setCurrencyID,
        setCurrencyCode,
        setCurrencies,
      ) => {
        try {
          const res = await GetAccountCurrencies({
            companyID: values.companyID,
            accountCode: accountCode,
          });

          if (res.data && res.data.length === 1) {
            const item = res.data[0];
            setCurrencyID(item.currencyID);
            setCurrencyCode(item.currencyCode);
          }
          setCurrencies(res.data);
        } catch (error) {
          console.error(
            `Error fetching account currencies for account ${accountCode}`,
            error,
          );
        }
      };

      if (values.firstAccount) {
        fetchAccountCurrencies(
          values.firstAccount,
          (value) => setFieldValue('currencyID', value),
          (value) => setFieldValue('currencyCode', value),
          setFirstCurrencies,
        );
      }

      if (values.secondAccount) {
        fetchAccountCurrencies(
          values.secondAccount,
          (value) => setFieldValue('credCurrID', value),
          (value) => setFieldValue('credCurrCode', value),
          setSecondCurrencies,
        );
      }
    }, [values.companyID, values.firstAccount, values.secondAccount]);

    useEffect(() => {
      if (!values.partnerID) {
        getRequest(`getCompanyAccounts?companyID=${currentCompanyID}`).then(
          (res) => {
            setFirstAccountData(res.data);
          },
        );
      } else if (values?.partnerID) {
        getRequest(`getAccountsForPartner?companyID=${currentCompanyID}`).then(
          (res) => {
            setFirstAccountData(res.data);
          },
        );
      }

      if (
        values.firstAccount &&
        values.partnerID &&
        values.rePaySign &&
        values.currencyID
      ) {
        getRequest(
          `getCorrespAccounts?companyID=${currentCompanyID}&accountCode=${
            values.firstAccount
          }&debitCredit=${values.debitCredit}&currencyID=${
            values.currencyID
          }&partner=${true}`,
        )
          .then((res) => {
            setSecondAccountData(res.data);
          })
          .catch((err) => {
            console.log('Err' + err);
          });
      }
      if (values.firstAccount && values.currencyID && !values.rePaySign) {
        getRequest(`getCompanyCorrespAccounts?companyID=${currentCompanyID}&currencyID=${
          values.currencyID
        }&accountCode=${values.firstAccount}
        &debitcredit=${values.debitCredit}&partner=${
          values.partnerID ? true : false
        }`)
          .then((res) => {
            setSecondAccountData(res.data);
          })
          .catch((err) => {});
      }
      return () => {};
    }, [
      currentCompanyID,
      values.currencyID,
      values.debitCredit,
      values.firstAccount,
      values.partnerID,
      values.rePaySign,
    ]);

    useEffect(() => {
      const targetCurrencyCode =
        values.currencyCode && values.currencyCode !== 'AMD'
          ? values.currencyCode
          : values.credCurrCode && values.credCurrCode !== 'AMD'
          ? values.credCurrCode
          : '';

      if (docDate === values.docDate) {
        setFieldValue('exchangeRate', generalExchangeRate);
      } else if (targetCurrencyCode) {
        const url = `getRate?rateDate=${dataTimeFormat(
          values.docDate,
        )}&iso=${targetCurrencyCode}`;
        getRequest(url)
          .then((res) => {
            let resp = res?.data
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            setFieldValue('exchangeRate', resp);
          })
          .catch((err) => {
            console.error(err, 'err');
          });
      } else {
        setFieldValue('exchangeRate', 1);
      }

      return () => {};
    }, [values.currencyCode, values.credCurrCode, values.docDate]);

    useEffect(() => {
      const { exchangeRate, amount } = values;
      if (exchangeRate === 1) {
        setFieldValue('amountAMD', amount);
      }
    }, [values]);

    const handleChangeCurrency = (values, name) => {
      if (!values) {
        values = {
          value: '',
          id: '',
        };
      }

      if (name === 'currencies') {
        setFieldValue('currencyID', values.id);
        setFieldValue('currencyCode', values.value);
      }

      if (name === 'credCurrencies') {
        setFieldValue('credCurrID', values.id);
        setFieldValue('credCurrCode', values.value);
      }

      setFieldValue('amountAMD', 0);
      setFieldValue('amount', 0);
    };

    const handleChangePartner = (values) => {
      if (!values) {
        values = {
          value: '',
          id: 0,
        };
        setFieldValue('firstAccount', '');
        setFieldValue('secondAccount', '');

        setFieldValue('currencyID', '');
        setFieldValue('currencyCode', '');

        setFieldValue('credCurrID', '');
        setFieldValue('credCurrCode', '');
      }
      if (values) {
        setFieldValue('firstAccount', '');
        setFieldValue('secondAccount', '');
      }
      setFieldValue('partnerID', values.id);
      setFieldValue('partnerName', values.value);
    };

    const handleChangeFirstAccount = (values) => {
      setFieldValue('firstAccount', '');
      if (!values) {
        values = {
          value: '',
        };
        setFieldValue('secondAccount', '');

        isAccountChanceClearCurrency();
      }

      setFieldValue('firstAccount', values.value);
    };

    const handleChangeSecondAccount = (values) => {
      setFieldValue('secondAccount', '');
      if (!values) {
        values = {
          value: '',
        };
        isAccountChanceClearCurrency();
      }
      setFieldValue('secondAccount', values.value);
    };

    const openPartnerAddForm = () => {
      setShowPartnerForm(true);
    };

    const isAccountChanceClearCurrency = () => {
      setFieldValue('currencyID', '');
      setFieldValue('currencyCode', '');
      setFieldValue('credCurrID', '');
      setFieldValue('credCurrCode', '');
    };

    let exchangeRateChange = AllowedAmountAmd(values);

    const onChangeExchangeRate = () => {
      if (!exchangeRateChange) {
        setFieldValue(
          'amountAMD',
          removeCommas(values.amount) * generalExchangeRate,
        );
      } else if (exchangeRateChange) {
        setFieldValue('amountAMD', 0);
        setFieldValue('amount', 0);
      }
    };

    useEffect(() => {
      if (exchangeRate !== generalExchangeRate) {
        onChangeExchangeRate();
      }
    }, [generalExchangeRate, exchangeRate]);

    const onChangeData = (date) => {
      setFieldValue('docDate', dataTimeFormat(date));
    };

    const onExchangeHandler = (e) => {
      const { value, name } = e.target;
      let curVal =
        name == 'amountAMD'
          ? removeCommas(value)
          : removeCommas(value.amountAMD);
      let curVal2 =
        name == 'amount' ? removeCommas(value) : removeCommas(value.amount);

      if (name == 'amountAMD') {
        setFieldValue('amount', curVal / generalExchangeRate);
        setFieldValue('amountAMD', curVal);
      } else if (name == 'amount') {
        setFieldValue('amountAMD', curVal2 * generalExchangeRate);
        setFieldValue('amount', curVal2);
      } else {
        handleChange(e);
      }
    };

    const onExchangeRateHandler = (e) => {
      const { value, name } = e.target;
      if (name == 'exchangeRate') {
        setFieldValue('exchangeRate', removeCommas(value));
      }
    };

    const newPartner = ({ partnerID, partnerName }) => {
      setFieldValue('partnerID', partnerID);
      setFieldValue('partnerName', partnerName);
    };

    const isRegisterReportExpenditureFunds =
      values && values.partnerID && values.firstAccount?.includes('228')
        ? true
        : false;

    const isDisabledDebCredBtn =
      values.firstAccount && values.secondAccount ? true : false;

    return (
      <>
        <Modal
          closeHandler={closeModal}
          onDrag={true}
          customClass="L-operations-form"
        >
          <div className="L-operations-form-content">
            <div className="G-modal-block-title">
              <h3>Հիշարար օրդեր</h3>
            </div>

            <div className="G-calendar-and-document-block G-flex">
              <div className="G-title-p">
                <p>Ամսաթիվ</p>
                <Calendar
                  openToDate={new Date(values?.docDate)}
                  value={dateFormat(values.docDate)}
                  changeHandler={(date, e) => onChangeData(date, e)}
                  name="docDate"
                />
              </div>
              <DocNumFiled
                docTypeID={values.docTypeID}
                docDate={values.docDate}
                value={values.docNum}
                setFieldValue={setFieldValue}
                handleChangeDocNum={handleChange}
                addEvent={addEvent}
              />
            </div>

            <form onSubmit={onSubmit}>
              <div className="L-debit-credit-block G-flex">
                <RadioButton
                  value={values.debitCredit}
                  checked={values.debitCredit === 1}
                  id="debitCredit"
                  name="debitCredit"
                  label="Դեբետ"
                  onChange={() => setFieldValue('debitCredit', 1)}
                  disabled={isDisabledDebCredBtn}
                />
                <RadioButton
                  value={values.debitCredit}
                  checked={values.debitCredit === 2}
                  id="debitCredit"
                  name="debitCredit"
                  label="Կրեդիտ"
                  onChange={() => setFieldValue('debitCredit', 2)}
                  disabled={isDisabledDebCredBtn}
                />

                {initAmount > 0 && (
                  <div className="L-available-amount">
                    <p className="G-title-p">
                      Առկա գումարը
                      <strong className="G-available-amount">
                        {Commas(initAmount)}
                      </strong>
                    </p>
                  </div>
                )}
              </div>
              <div className="G-flex-align-center">
                <SelectPartner
                  partnerData={operationsData?.partners}
                  partnerVal={{
                    label: values.partnerName,
                    value: values.partnerName,
                  }}
                  selectChangePartner={handleChangePartner}
                />
                <AddNew onClick={openPartnerAddForm} />
              </div>
              <div className="G-description-input">
                <Checkbox
                  checked={values.rePaySign}
                  label="Գործընկերոջ հաշիվների փոխմարում"
                  name="partnerForm"
                  value={values.rePaySign}
                  onChange={() => setFieldValue('rePaySign', !values.rePaySign)}
                  disabled={!values.partnerID}
                />
              </div>
              <div className="L-operations-flex">
                <SelectAccount
                  customClass="L-debit-credit-wrapper"
                  title={values.debitCredit === 1 ? 'Դեբետ' : 'Կրեդիտ'}
                  accountData={firstAccountData}
                  accountVal={{
                    label: values.firstAccount,
                    value: values.firstAccount,
                  }}
                  selectChangeAccount={(values) =>
                    handleChangeFirstAccount(values)
                  }
                  onBlur={handleBlur}
                />
                <SelectCurrency
                  currencyData={firstCurrencies}
                  currencyVal={{
                    label: values.currencyCode,
                    value: values.currencyCode,
                  }}
                  selectChangeCurrency={(values) =>
                    handleChangeCurrency(values, 'currencies')
                  }
                  onBlur={handleBlur}
                />
              </div>

              <div className="L-operations-flex">
                <SelectAccount
                  customClass="L-debit-credit-wrapper"
                  title={values.debitCredit !== 2 ? 'Կրեդիտ' : 'Դեբետ'}
                  accountData={secondAccountData}
                  accountVal={{
                    label: values.secondAccount,
                    value: values.secondAccount,
                  }}
                  onBlur={handleBlur}
                  selectChangeAccount={(values) =>
                    handleChangeSecondAccount(values)
                  }
                  disabled={values?.firstAccount === ''}
                />
                <SelectCurrency
                  currencyData={secondCurrencies}
                  currencyVal={{
                    label: values.credCurrCode,
                    value: values.credCurrCode,
                  }}
                  selectChangeCurrency={(values) =>
                    handleChangeCurrency(values, 'credCurrencies')
                  }
                  onBlur={handleBlur}
                />
              </div>

              {isRegisterReportExpenditureFunds && (
                <div className="G-register-calculationIn-report-block">
                  <Checkbox
                    checked={values.cashSign}
                    label="Գրանցել առհաշիվ գումարների ծախսման մասին հաշվետվության մեջ"
                    name="cashSign"
                    value={values.cashSign}
                    onChange={() => setFieldValue('cashSign', !values.cashSign)}
                  />
                </div>
              )}

              <div className="G-flex L-operations-amount-flex">
                <ExchangeRateFiled
                  value={values?.exchangeRate}
                  changeHandler={onExchangeRateHandler}
                  currencyCode={
                    values.currencyCode !== 'AMD'
                      ? values.currencyCode
                      : values.credCurrCode
                  }
                />
                <div className="G-amount-input">
                  <NumericFormatFiled
                    value={values.amount}
                    allowNegative={true}
                    name="amount"
                    onBlur={handleBlur}
                    changeHandler={onExchangeHandler}
                    inputTitle="Գումարը"
                    requiredFiled={
                      !exchangeRateChange ? true : exchangeRateChange
                    }
                  />
                  {touched.amount && errors.amount && (
                    <ErrorFiled error={errors.amount} />
                  )}
                </div>

                <NumericFormatFiled
                  value={values.amountAMD}
                  allowNegative={true}
                  name="amountAMD"
                  inputTitle="Գումարը AMD"
                  onBlur={handleBlur}
                  changeHandler={onExchangeHandler}
                  requiredFiled={exchangeRateChange}
                  disabled={!exchangeRateChange}
                />
              </div>

              <div className="G-description-input">
                <InputFiled
                  inputTitle="Նշումներ"
                  name="description"
                  value={values.description}
                  changeHandler={handleChange}
                  onBlur={handleBlur}
                  maxLength={textFiledLength}
                />
              </div>
              <div className="G-save-cancel-btn-width">
                <GetSentDataBtn
                  cancelPrintClick={closeModal}
                  confirmExecuteClick={handleSubmit}
                  executeClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
                  executeDisabled={!(isValid && dirty)}
                />
              </div>
            </form>
          </div>
        </Modal>
        {showPartnerForm && (
          <PartnerForm
            closeModal={setShowPartnerForm}
            updateUserOutside={true}
            updatePartnerAction={updatePartnerAction}
            setPartnerInfo={newPartner}
          />
        )}
      </>
    );
  },
);

export default OperationsForm;
