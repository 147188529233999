import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import Button from '../../../App/Components/Button/Button';
import { actionConfigs, CALCULATE, DELETE_CALCULATE } from './actionConfigs';

const SalaryCalculationAction = ({
  employeesForCalc,
  values,
  setValues,
  onCalculateClick,
}) => {
  const optionsData = employeesForCalc || [];

  const handleDropdownChange = (selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const { label, id } = selectedOption;
    setValues((prevValues) => ({
      ...prevValues,
      depName: label,
      depNo: id?.trim(),
    }));
  };
  const handleButtonClick = (config) => {
    const { onClickHandler, actionType } = config || {};

    if (typeof onClickHandler === 'function') {
      switch (actionType) {
        case CALCULATE:
          onCalculateClick();
          break;
        // case DELETE_CALCULATE:
        //   return null
        //   break;
        default:
          break;
      }
    }
  };
  return (
    <SearchBackground>
      <div className="G-salary-calculation-form">
        {actionConfigs?.map((config, index) => {
          if (config.type === 'dropdown') {
            const dropDownOptions = optionsData
              ? config.Children(optionsData[config.optionName])
              : [];

            return (
              <div className="L-salary-calculation-filed" key={index}>
                <SelectControl
                  value={{ label: values[config.optionName] }}
                  selectBoxTitle={config.title}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    handleDropdownChange(selectedOption, config)
                  }
                  requiredFiled={config.required}
                />
              </div>
            );
          } else if (config.type === 'button') {
            return (
              <div className="G-add-btn" key={index}>
                <Button
                  type="button"
                  text={config.buttonText}
                  onClick={() => handleButtonClick(config)}
                  // disabled={config.disabled(values)}
                  // customClass={
                  //   config.disabled(values) ? 'G-disabled-button' : ''
                  // }
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </SearchBackground>
  );
};

export default SalaryCalculationAction;
