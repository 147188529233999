import { useEffect, useState } from 'react';
import { fetchWorkWithEmployees } from '../services/fetchWorkWithEmployees';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkWithEmployeesAction } from '../../App/Redux/SalaryStor/workWithEmployeesSlices/useAction';

const useWorkWithEmployees = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const workWithEmployees = useSelector(
    (state) => state.workWithEmployees.employeesInfo,
  );

  const dispatch = useDispatch();
  const getWorkWithEmployees = async (query, pageNumber) => {
    setIsLoading(true);
    try {
      const data = await fetchWorkWithEmployees(
        currentCompanyID,
        query,
        pageNumber,
      );
      dispatch(fetchWorkWithEmployeesAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    error,
    errorMess,
    isLoading,
    getWorkWithEmployees,
    workWithEmployees,
  };
};

export default useWorkWithEmployees;
