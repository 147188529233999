import React from 'react';
import { Commas } from '../../Utilities/Utilities';
import './style.scss';

const TotalAmount = ({ arr }) => {
  const ReduceSum = (arr = []) => {
    const sum = arr.reduce((total, item) => total + item.amount, 0);
    return Commas(sum);
  };
  const res = arr?.length > 0 ? ReduceSum(arr) : null;

  return (
    <div className="G-total-wrap">
      <p>Հանրագումարը {res} դրամ</p>
    </div>
  );
};

export default TotalAmount;
