import React from "react";
import AuthInfo from "../../Pages/AuthInfo/AuthInfo";
import TopArrow from '../TopArrow/TopArrow';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import './topMenu.scss';
import { authClear } from '../../Redux/authSlices/useAction';
import { windowReload } from '../../Utilities/Utilities';

const TopMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clearUser = () => {
    dispatch(authClear());
    localStorage.removeItem('persist:root');
    history.push('/OneWindow');
    windowReload();
  };
  return (
    <div className="L-top-menu-block G-flex-justify-between">
      <div className="" onClick={clearUser}>
        <TopArrow />
      </div>

      <AuthInfo />
    </div>
  );
};

export default TopMenu;
