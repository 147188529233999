import React, { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Action from "../../Components/Table/Action";
import Table from "../../Components/Table/Table";
import { editDeleteList } from "../../OptionsList/OptionsList";
import {
  MAIN_URL,
  numbering,
  tableActionTitle,
} from '../../Utilities/Utilities';
import DeleteUnitOfMeasure from './DeleteUnitOfMeasure';
import EventEditForm from './EventEditForm';

const UnitOfMeasureTable = ({ mainData, loading }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handelChange = (value, row) => {
    switch (value) {
      case 'edit':
        return openEditForm(row);
      case 'delete':
        return openDeleteForm(row?.unitID);

      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: numbering,
      cell: (row) => row.sequenceNumber,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Կարճ անվանումը',
      cell: (row) => row.unitCode,
      customStyle: { maxWidth: 1250 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.unitName,
      customStyle: { maxWidth: 1250 },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={editDeleteList}
          modalIsOpen={openEditModal || openDeleteModal}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  const openEditForm = (rowValue) => {
    setOpenEditModal(rowValue);
  };

  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-unitOfMeasure-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openEditModal && (
        <EventEditForm rowData={openEditModal} closeModal={setOpenEditModal} />
      )}
      {openDeleteModal && (
        <DeleteUnitOfMeasure
          deleteRowId={openDeleteModal}
          closeModal={setOpenDeleteModal}
        />
      )}
    </>
  );
};

export default UnitOfMeasureTable;
