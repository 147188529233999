import React, { useEffect, useState } from 'react';
import TabButtons from './TabButtons';
import GeneralVacationForm from './GeneralVacationForm';
import VacationAveragesForm from './VacationAveragesForm';
import VacationStory from './VacationStory';
import './vacationCalculation.scss';
import { dataTimeFormat, removeCommas } from '../../../App/Utilities/Utilities';
import useVacationCalculation from '../../hooks/useVacationCalculation';
import useWorkWithEmployees from '../../hooks/useWorkWithEmployees';
import Loading from '../../../App/Components/Loading/Loading';
import Alert from '../../../App/Components/Alert';
import VacationCalculationHeader from './VacationCalculationHeader';
import { VacationCalcValidation } from '../../../App/Validation/VacationCalcValidation';
import { useFormik } from 'formik';
import useIsMounted from '../../hooks/useIsMounted';

const VacationCalculation = ({ rowValues, handleClose }) => {
  const isMounted = useIsMounted();
  const [activeTab, setActiveTab] = useState('GeneralVacationForm');
  const { empNo } = rowValues || {};
  const { workWithEmployees } = useWorkWithEmployees();
  const {
    getEmployeeVacation,
    generalVacationFormData,
    addEmployeeVacation,
    isLoading,
    addSaveAverages,
    errorMess,
    setErrorMess,
  } = useVacationCalculation();

  useEffect(() => {
    async function fetchEmployeeVacation() {
      try {
        await getEmployeeVacation(
          dataTimeFormat(workWithEmployees?.salaryDate),
          empNo,
        );
      } catch (error) {
        console.error('Failed to fetch employee vacation:', error);
      }
    }
    fetchEmployeeVacation();
  }, [empNo, workWithEmployees?.salaryDate]);

  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...generalVacationFormData?.forVacationSave },
    validationSchema: VacationCalcValidation,
    onSubmit: async (values, { resetForm }) => {
      const newValues = {
        ...values,
        monthlyAvg: removeCommas(values.monthlyAvg),
        dailyAvg: removeCommas(values.dailyAvg),
        vacDays: Number(values.vacDays),
      };
      try {
        await addEmployeeVacation(newValues, resetForm);
        if (isMounted) {
          resetForm();
        }
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    },
  });

  const commonProps = {
    generalVacationFormData,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    handleClose,
  };

  const tabComponents = {
    GeneralVacationForm: <GeneralVacationForm {...commonProps} />,
    VacationAveragesForm: (
      <VacationAveragesForm
        mainData={generalVacationFormData?.averageData}
        saveAverages={addSaveAverages}
      />
    ),
    VacationStory: (
      <VacationStory vacationStory={generalVacationFormData?.nonWorkings} />
    ),
  };

  return (
    <>
      <VacationCalculationHeader data={generalVacationFormData} />
      {generalVacationFormData?.message?.length > 0 ? (
        <p className="G-title-p" style={{ textAlign: 'center' }}>
          {generalVacationFormData?.message}
        </p>
      ) : (
        <>
          <TabButtons activeTab={activeTab} handleTabChange={setActiveTab} />

          <div className="L-vacation-calculation-tab-content">
            {tabComponents[activeTab]}
          </div>

          <Alert description={errorMess} onClose={setErrorMess} />
        </>
      )}
      {isLoading && <Loading />}
    </>
  );
};

export default VacationCalculation;
