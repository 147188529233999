import { getRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
export const fetchCalculationSheets = async (companyID) => {
  try {
    const response = await getRequest(
      `getSalaryTotalsData?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchCalculationSheetMonth = async (companyID, year) => {
  if (!year) {
    return;
  }
  try {
    const response = await getRequest(
      `getSalaryMonths?companyID=${companyID}&year=${year}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const executeCalculationSheets = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getSalaryTotals?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
