import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, postRequest } from '../../Api/Api';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import SearchBar from '../../Components/SearchForm/SearchBar';
import {
  getCirculationAction,
  getSummaryAction,
  loadingAction,
} from '../../Redux/circulationNewsletterSlices/useAction';
import {
  correctDate,
  dataTimeFormat,
  inCorrectDateMess,
  initialDate,
  viewPrintTitle,
} from '../../Utilities/Utilities';
import CirculationNewsletterTable from './CirculationNewsletterTable';
import './circulationNewsletter.scss';
import Button from '../../Components/Button/Button';
import ViewSummary from './ViewSummary';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import Alert from '../../Components/Alert';

const GetCirculationNewsletterData = () => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);
  const { circulationData, isLoading, checkPrintSingAll } = useSelector(
    (state) => state.getCirculationData,
  );

  const [searchAccount, setSearchAccount] = useState('');
  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dispatch = useDispatch();
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [printSignRow, setPrintSignRow] = useState([]);
  const [viewSummary, setViewSummary] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const { data } = useFetchCurrency();

  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });

  const disabledBtn = currencyVal?.value === '' || printSignRow?.length === 0;
  const dateUrl = `dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`;
  const getData = useCallback(() => {
    dispatch(loadingAction(true));
    getRequest(
      `getAccountsList?companyID=${currentCompanyID}&dateStart=${dateStartFormat}&${dateUrl}&searchstring=${searchAccount}`,
    )
      .then((res) => {
        dispatch(getCirculationAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  }, [currentCompanyID, dateStartFormat, dateUrl, dispatch, searchAccount]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const filterRow = circulationData?.filter((row) => {
      return row?.printSign || row?.debitSign || row?.creditSign;
    });
    setPrintSignRow(filterRow);
  }, [circulationData]);

  const openViewSummaryModal = useCallback(() => {
    if (printSignRow.length > 0) {
      const currencyID =
        currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id;
      postRequest(
        `getCalculationReport?companyID=${currentCompanyID}&currencyID=${currencyID}&${dateUrl}&forAll=${checkPrintSingAll}`,
        printSignRow,
      ).then((res) => {
        dispatch(getSummaryAction(res.data));
        setViewSummary(true);
      });
    } else {
      setViewSummary(false);
    }
  }, [
    currentCompanyID,
    dispatch,
    dateUrl,
    printSignRow,
    currencyVal,
    data,
    checkPrintSingAll,
  ]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const changeCurrency = useCallback((value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setCurrencyVal(value);
  }, []);

  return (
    <>
      <Header headerBtn={false} pageTitle="Շրջանառության տեղեկագիր" />
      <SearchBackground>
        <div className="L-circulation-newsletter G-justify-between-align-center">
          <CalendarTime
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            onChangeHandler={timeChangeHandler}
          />
          <SelectCurrency
            currencyData={data?.currencies}
            currencyVal={currencyVal}
            selectChangeCurrency={(value) => changeCurrency(value)}
          />

          <SearchBar
            getData={getData}
            searchValue={searchAccount}
            setSearchValue={setSearchAccount}
            setPageNumber={setSearchAccount}
          />
          <div className="G-view-btn">
            <Button
              text={viewPrintTitle}
              onClick={openViewSummaryModal}
              disabled={disabledBtn}
              customClass={disabledBtn ? 'G-disabled-button' : ''}
            />
          </div>
        </div>
      </SearchBackground>
      <CirculationNewsletterTable
        mainData={circulationData}
        loading={isLoading}
      />

      {viewSummary && (
        <ViewSummary
          modalClick={setViewSummary}
          dateStart={dateStart}
          dateEnd={dateEnd}
          dateStartFormat={dateStartFormat}
          dateEndFormat={dateEndFormat}
          currencyID={
            currencyVal?.id === 0 ? data?.amdCurrencyID : currencyVal.id
          }
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetCirculationNewsletterData;
