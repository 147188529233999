import React from 'react';
import Input from '../../Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { PersonalCardFields } from './FieldsConfig';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';

import DateInput from '../../components/dateInput/DateInput';

const PersonalCard = ({
  onChange,
  employeesFormData,
  setFieldValue,
  values,
  errors,
  touched,
  handleBlur,
}) => {
  let dropDownOptions = [];

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id
      ? selectedOption.id.toString().trim()
      : '';

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  const onChangeDate = (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      return;
    }
    setFieldValue(name, date);
  };

  return (
    <div className="L-personal-card-comp">
      {PersonalCardFields?.map((field) => {
        const {
          fieldType,
          fieldName,
          label,
          name,
          required,
          Children,
          optionName,
          dropdownTitle,
          dateName,
          type,
          dateTitle,
          dropdownId,
          maxDate,
          minDate,
        } = field;

        const fieldError = touched[name] && errors[name];

        if (typeof Children === 'function') {
          if (employeesFormData) {
            dropDownOptions = Children(employeesFormData[optionName]);
          }
        }

        switch (fieldType) {
          case 'input':
            return (
              <div key={name} className="input-container">
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredFiled={required}
                  onChange={onChange}
                  value={values[name]}
                  onBlur={handleBlur}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );
          case 'dropdown':
            return (
              <div key={fieldName} className="select-container">
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={required}
                  value={{
                    label: values[fieldName] || '',
                    value: values[fieldName] || '',
                  }}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    updateFieldValueAndDropdown(
                      fieldName,
                      selectedOption,
                      dropdownId,
                    )
                  }
                  onBlur={handleBlur}
                />
                {required && !values[fieldName] && fieldError && (
                  <ErrorFiled error={fieldError} />
                )}
              </div>
            );
          case 'date':
            return (
              <div className="date-block" key={dateName}>
                <DateInput
                  value={values[dateName] || ''}
                  onChange={(date) => onChangeDate(date, dateName)}
                  name={dateName}
                  title={dateTitle}
                  requiredFiled={required}
                  maxDate={maxDate}
                  minDate={minDate}
                />
                {required && !values[dateName] && fieldError && (
                  <ErrorFiled error={fieldError} />
                )}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default PersonalCard;
