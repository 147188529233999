import React, { useEffect, useState } from 'react';
import useAssignPositions from '../../hooks/useAssignPositions';
import Header from '../../../App/Components/Header/Header';
import AssignPositionsTable from './AssignPositionsTable';
import ExecuteAction from './ExecuteAction';
import { simplifyObject } from '../../../App/Utilities/Utilities';
import './style.scss';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
const AssignPositions = () => {
  const {
    assignPosition,
    getExecuteAssignPosition,
    executeAssignPosition,
    addAssignPositionsItems,
    removeAssignPositionsItem,
    isRegister,
  } = useAssignPositions();

  const [query, setQuery] = useState({
    stNo: '',
    depNo: '',
  });

  const [updateData, setUpdateData] = useState(executeAssignPosition);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setUpdateData(executeAssignPosition);
  }, [executeAssignPosition]);

  const modifyObj = simplifyObject(query);

  const onExecuteHandler = () => {
    getExecuteAssignPosition(modifyObj);
  };

  const onRemoveRow = async () => {
    await removeAssignPositionsItem(query);
    setIsDisabled(true);
  };

  const onConfirm = async () => {
    await addAssignPositionsItems(updateData);
    setIsDisabled(true);
  };

  return (
    <div className="L-assign-positions-wrap">
      <Header
        pageTitle="Նշանակել հաստիքներ"
        headerBtn={false}
        showCalculationDate={true}
      />
      <ExecuteAction
        query={query}
        setQuery={setQuery}
        assignPositionData={assignPosition}
        onExecuteHandler={onExecuteHandler}
      />
      <AssignPositionsTable
        updateData={updateData}
        setUpdateData={setUpdateData}
        isRegister={isRegister}
        setIsDisabled={setIsDisabled}
      />
      {executeAssignPosition?.salarySets?.length > 0 && (
        <GetSentDataBtn
          cancelPrintClickText="Հաստատել"
          confirmExecuteText="Ջնջել"
          cancelPrintClick={onConfirm}
          confirmExecuteClick={onRemoveRow}
          executeClass={isDisabled ? 'G-disabled-button' : ''}
          executeDisabled={isDisabled}
          disabled={isDisabled}
          customClass={isDisabled ? 'G-disabled-button' : ''}
        />
      )}
    </div>
  );
};

export default AssignPositions;
