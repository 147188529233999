import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloudUpload } from '../../Assets/icons/cloudUpload.svg';
import Alert from '../../Components/Alert';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import Modal from '../../Components/Modal/Modal';

import { postRequest } from '../../Api/Api';
import { convertBase64 } from '../../Utilities/Utilities';
import { updateUser } from '../../Redux/authSlices/useAction';
import './style.scss';

const SelectUserPicture = React.memo(({ closeModal, img, getUserImg }) => {
  const handleClose = useCallback(() => closeModal(false), [closeModal]);
  const { userID } = useSelector((state) => state.auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState('');
  const [errorHandler, setErrorHandler] = useState('');
  const [baseImage, setBaseImage] = useState('');
  const dispatch = useDispatch();

  const uploadImage = useCallback(async (e) => {
    const file = e.target.files[0];
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setSelectedFile(file);
        setFilename(file.name);

        const base64 = await convertBase64(file);
        setBaseImage(base64);
      } else {
        setErrorHandler('Թույլատրվում են միայն JPG, PNG կամ SVG ֆայլեր:');
      }
    }
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append('name', filename);
      formData.append('file', selectedFile);

      if (filename) {
        postRequest(`importPicture?userID=${userID}`, formData)
          .then((res) => {
            if (res.data.errorCode === 0) {
              dispatch(
                updateUser({
                  picture: img,
                }),
              );
              getUserImg();
            }
            if (res.data.errorCode > 0) {
              setErrorHandler(res.data.message);
            }
          })
          .catch((err) => {
            console.log('err' + err);
          });
      }
    },
    [filename, selectedFile, userID, img, dispatch, getUserImg],
  );

  const cancelHandler = useCallback(() => {
    setSelectedFile(null);
    setBaseImage(null);
  }, []);

  return (
    <Modal customClass="G-select-user-picture" closeHandler={handleClose}>
      <form onSubmit={onSubmit}>
        <div className="form">
          <label htmlFor="customFile" className="L-upload-label">
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .svg"
              id="customFile"
              onChange={uploadImage}
              className="choose-img-input"
            />
            <div className="img-text">
              <span className="fa-cloud-upload-alt">
                <CloudUpload />
              </span>
              <h3>Ավելացնել նկար</h3>
            </div>
          </label>
        </div>
        {baseImage && (
          <div className="user-picture">
            <img src={baseImage} alt="" />
          </div>
        )}

        <div className="G-save-cancel-btn-width">
          <GetSentDataBtn
            cancelPrintClick={cancelHandler}
            confirmExecuteClick={onSubmit}
            executeClass={!selectedFile ? 'G-disabled-button' : ''}
          />
        </div>
      </form>
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </Modal>
  );
});

export default SelectUserPicture;
