import React, { useEffect } from 'react';
import { OtherDataFields } from './FieldsConfig';
import Input from '../../Input/Input';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import useGetBankByCode from '../../hooks/useGetBankByCode';
const OtherData = ({
  onChange,
  values,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) => {
  const { bankByCode } = useGetBankByCode(values.cardAcc);

  useEffect(() => {
    setFieldValue('bankName', bankByCode);
  }, [bankByCode]);

  return (
    <div className="other-data-comp">
      {OtherDataFields?.map((field) => {
        const { fieldType, label, name, required, disabled, maxlength } = field;
        const fieldError = touched[name] && errors[name];
        switch (fieldType) {
          case 'input':
            return (
              <div className="input-container">
                <Input
                  key={name}
                  inputTitle={label}
                  name={name}
                  requiredFiled={required}
                  onChange={onChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  disabled={disabled}
                  maxlength={maxlength}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default OtherData;
