import ReactPaginate from 'react-paginate';
import './pagination.scss';
const Pagination = ({ pageCount = 1, setPageNumber, currentPage }) => {
  const handlePageChange = (selectedPage) => {
    setPageNumber(selectedPage.selected);
  };

  return (
    <div className="G-pagination-block">
      <p className="G-page-count">
        {currentPage + 1}/{pageCount}-ից
      </p>
      <ReactPaginate
        initialPage={currentPage}
        breakLabel="..."
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        previousLabel=""
        nextLabel=""
        onPageChange={handlePageChange}
        pageRangeDisplayed={4}
        containerClassName={'paginationBttns'}
        previousClassName={'previouseBttn'}
        nextClassName={'nextBttn'}
        disabledClassName={'paginationDisabled'}
        activeClassName={'paginationActive'}
        forcePage={currentPage}
      />
    </div>
  );
};

export default Pagination;
