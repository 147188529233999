import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import EventAddForm from './EventAddForm';
import './index.scss';
import EmployeesTable from './EmployeesTable';
import useEmployeesForm from '../../hooks/useEmployeesForm';
import AdvancedSearch from './AdvancedSearch';
import { simplifyObject } from '../../../App/Utilities/Utilities';
import { ConvertEmployeesExcel } from '../../Config/excelConfig';
import { useSelector } from 'react-redux';
import Pagination from '../../../App/Components/Pagination/Pagination';

const Employees = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { name, id } = useSelector(
    (state) => state.employeesData?.filteredItem,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState({
    name: '',
    rentName: '',
    rentNo: '',
    depNo: id,
    depName: name,
  });

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  const modifyObj = simplifyObject(query);

  const { employees, fetchEmployeesData, isLoading } = useEmployeesForm();

  useEffect(() => {
    fetchEmployeesData(modifyObj, pageNumber);
  }, [modifyObj, pageNumber]);

  const dataToExcel = ConvertEmployeesExcel(employees?.employees);

  const isDisabled =
    !employees?.workDeps?.length || !employees?.positions?.length;

  return (
    <>
      <Header
        pageTitle="Աշխատակիցներ"
        handelClick={onClose}
        disabledAddBtn={isDisabled}
      />
      <AdvancedSearch
        query={query}
        setQuery={setQuery}
        employeesFormData={employees}
        dataToExcel={dataToExcel}
      />
      <EmployeesTable mainData={employees?.employees} loading={isLoading} />
      {showAddForm && (
        <EventAddForm
          handleClose={onClose}
          query={query}
          pageNumber={pageNumber}
        />
      )}
      <Pagination
        pageCount={employees?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </>
  );
};

export default Employees;
