import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  fetchEmployeeVacation,
  fetchNonWorkings,
  saveAverages,
  saveEmployeeVacation,
} from '../services/fetchVacationCalculation';

const useVacationCalculation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [generalVacationFormData, setGeneralVacationFormData] = useState(null);

  const getEmployeeVacation = async (salaryDate, empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchEmployeeVacation(
        currentCompanyID,
        salaryDate,
        empNo,
      );
      setGeneralVacationFormData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addEmployeeVacation = async (newEmployeeVacation, resetForm) => {
    const { empNo } = newEmployeeVacation || {};
    try {
      const { errorCode, message } = await saveEmployeeVacation(
        newEmployeeVacation,
      );
      if (errorCode === 0) {
        resetForm();

        getNonWorkings(empNo);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  const getNonWorkings = async (empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchNonWorkings(currentCompanyID, empNo);
      setGeneralVacationFormData((prevFormData) => ({
        ...prevFormData,
        nonWorkings: [...data],
      }));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addSaveAverages = async (newAverages) => {
    try {
      const { errorCode, message } = await saveAverages(newAverages);
      if (errorCode === 0) {
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getEmployeeVacation,
    isLoading,
    error,
    errorMess,
    setErrorMess,
    generalVacationFormData,
    addEmployeeVacation,
    addSaveAverages,
    setGeneralVacationFormData,
  };
};

export default useVacationCalculation;
