import React, { useEffect, useState } from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import CalculationSheetTable from './CalculationSheetTable';
import CalculationSheetAction from './CalculationSheetAction';
import './style.scss';
import useCalculationSheet from '../../../hooks/useCalculationSheet';
import CalculationSheetHeader from './CalculationSheetHeader';
import CalculationSheetTotal from './CalculationSheetTotal';
import { printHandler } from '../../../../App/Utilities/Utilities';
import { useDispatch, useSelector } from 'react-redux';

const CalculationSheetModal = ({ handleClose, rowValues }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { empNo } = rowValues || {};
  const dispatch = useDispatch();
  const {
    getCalculationSheet,
    calculationSheet,
    calculationData,
    getSalaryCalculator,
    getSalaryCalculatorDate,
    totals,
    setTotals,
    removeCalculationSheetItem,
  } = useCalculationSheet();
  const [salaryMonthValue, setSalaryMonthValue] = useState({});
  const isValid = calculationSheet?.yearMonth !== salaryMonthValue?.value;
  const { year, month } =
    salaryMonthValue?.year !== undefined &&
    salaryMonthValue?.month !== undefined
      ? salaryMonthValue
      : calculationSheet || {};

  useEffect(() => {
    if (empNo) {
      getCalculationSheet(empNo);
    }
  }, [empNo]);

  const onToCalculate = async (yearMonth) => {
    try {
      await getSalaryCalculator(empNo, yearMonth);
    } catch (error) {}
  };

  const onToCalculatePrint = () => {
    if (!currentCompanyID || !empNo || !year || !month) {
      console.error('Missing required parameters for printing');
      return;
    }

    const url = `printEmployeeData?companyID=${currentCompanyID}&empNo=${empNo}&year=${year}&month=${month}`;

    printHandler(url, dispatch);
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-calculation-sheet-modal"
      title="Հաշվարկային թերթիկ"
    >
      <CalculationSheetHeader calculationSheet={calculationSheet} />
      <CalculationSheetAction
        calculationSheet={calculationSheet}
        onToCalculate={onToCalculate}
        getSalaryCalculatorDate={getSalaryCalculatorDate}
        salaryMonthValue={salaryMonthValue}
        setSalaryMonthValue={setSalaryMonthValue}
        isValid={isValid}
        onToCalculatePrint={onToCalculatePrint}
      />
      <CalculationSheetTable
        calculationSheet={calculationSheet}
        mainData={calculationData}
        isValid={isValid}
        removeCalculationSheetItem={removeCalculationSheetItem}
      />
      {calculationData && calculationData?.length > 0 && (
        <CalculationSheetTotal total={totals} setTotals={setTotals} />
      )}
    </Modal>
  );
};

export default CalculationSheetModal;
