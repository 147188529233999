import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import InputFiled from '../../Components/InputFiled/InputFiled';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import { OrganizationFormValidation } from '../../Validation/OrganizationFormValidation';
import Button from '../../Components/Button/Button';

import {
  dataTimeFormat,
  dateFormat,
  inCorrectDateMess,
  register,
} from '../../Utilities/Utilities';
import Calendar from '../../Components/Calendar/Calendar';
import { useState } from 'react';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import HistoryTaxationSystem from '../HistoryTaxationSystem/HistoryTaxationSystem';
import Checkbox from '../../Components/Checkbox/Checkbox';

const OrganizationForm = ({ onSubmit, setErrorHandler, existingDate }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const boxesClassName = 'L-organization-form-box';
  const wrapperClassName = 'L-organization-box-wrapper';
  const [isChangeTaxationSystem, setIsChangeTaxationSystem] = useState(false);
  const [saveTaxationSystem, setSaveTaxationSystem] = useState({});
  const [showHistoryTaxationSystem, setShowHistoryTaxationSystem] =
    useState(false);

  const { organizationData } = useSelector(
    (state) => state.getOrganizationData,
  );

  const history = useHistory();
  const COMPANY_BANK_PATH = '/CompanyBank';
  const INITIAL_BALANCES_PATH = '/InitialBalances';
  const PARTNERS_PATH = '/Partners';
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    dirty,
    isValid,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...organizationData?.company,
    },
    validationSchema: OrganizationFormValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const companyTypesOptions = organizationData?.companyTypes?.map(
    ({ companyTypeName, companyTypeID }) => {
      return {
        label: companyTypeName,
        value: companyTypeName,
        id: companyTypeID,
      };
    },
  );

  const taxationTypesOptions = organizationData?.taxationSystems?.map(
    ({ taxationSystemName, taxationSystemID }) => {
      return {
        label: taxationSystemName,
        value: taxationSystemName,
        id: taxationSystemID,
      };
    },
  );

  const handleChangeCompanyType = useCallback(
    (values) => {
      if (!values) {
        values = {
          value: '',
          id: '',
        };
      }
      setFieldValue('companyTypeID', values.id);
      setFieldValue('companyTypeName', values.value);
    },
    [setFieldValue],
  );

  const onChangeTaxationSystem = (values) => {
    setSaveTaxationSystem(values);
    setIsChangeTaxationSystem(true);
  };

  const confirmChangeTaxationSystem = () => {
    setFieldValue('taxationID', saveTaxationSystem.id);
    setFieldValue('taxationSystemName', saveTaxationSystem.value);
    setIsChangeTaxationSystem(false);
  };

  const onChangeDate = (currentDate, name) => {
    const date = dataTimeFormat(currentDate);

    const d1 = name === 'startDate' ? currentDate : values.startDate;
    const d2 = name === 'endDate' ? currentDate : values.endDate;

    if (new Date(d1) > new Date(d2)) {
      setErrorHandler(inCorrectDateMess);
      return;
    } else if (name === 'startDate') {
      setFieldValue('startDate', date);
    } else if (name === 'endDate') {
      setFieldValue('endDate', date);
    }
  };

  const openBankForm = () => {
    navigateTo(COMPANY_BANK_PATH);
  };

  const goToTheInitBalance = () => {
    navigateTo(INITIAL_BALANCES_PATH);
  };

  const openPartnerForm = () => {
    navigateTo(PARTNERS_PATH);
  };

  function navigateTo(path) {
    history.push(path);
  }

  const onChangeTaxationDate = (newDateValue) => {
    const formattedDate = dataTimeFormat(newDateValue);

    setFieldValue('taxationDate', formattedDate);
  };

  return (
    <div className="L-organization-form-content">
      <form onSubmit={onSubmit}>
        <div className="L-organization-form-boxes-flex G-flex G-flex-wrap">
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Կազմակերպության անվանումը"
                name="companyName"
                value={values?.companyName}
                changeHandler={handleChange}
                requiredFiled={true}
                onBlur={handleBlur}
              />
              {touched.companyName && errors?.companyName && (
                <ErrorFiled error={errors.companyName} />
              )}
            </div>
          </div>

          <div className={`${wrapperClassName} G-flex`}>
            <div className={`${boxesClassName} `}>
              <SelectControl
                isClearable={false}
                selectBoxTitle="Հարկման համակարգ"
                options={taxationTypesOptions}
                value={{
                  label:
                    values?.taxationSystemName ||
                    existingDate?.taxationSystemName,
                  value:
                    values?.taxationSystemName ||
                    existingDate?.taxationSystemName,
                }}
                onChange={(values) => onChangeTaxationSystem(values)}
                requiredFiled={true}
                onBlur={handleBlur}
              />
              {touched.taxationID && errors.taxationID && (
                <ErrorFiled error={errors.taxationID} />
              )}
            </div>
            <Checkbox
              checked={values.itSign}
              label="ՏՏ ոլորտ"
              name="itSign"
              value={values.itSign}
              onChange={() => setFieldValue('itSign', !values.itSign)}
            />
          </div>

          {currentCompanyID > 0 && (
            <div
              className={`L-taxation-block G-flex-justify-center ${wrapperClassName}`}
            >
              <div className="L-taxation-block-content">
                <div className={`${boxesClassName}`}>
                  <label className="L-reporting-period-title">
                    Հարկման համակարգ
                  </label>
                  <p className="G-title-p">Փոփոխման ամսաթիվը</p>
                  <Calendar
                    value={dateFormat(values?.taxationDate)}
                    changeHandler={(val) => onChangeTaxationDate(val)}
                    name="taxationDate"
                    openToDate={new Date(values?.taxationDate)}
                  />
                  {touched.taxationDate && errors?.taxationDate && (
                    <ErrorFiled error={errors.taxationDate} />
                  )}
                </div>

                <p
                  className="G-title-p"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowHistoryTaxationSystem(true)}
                >
                  {' '}
                  Պատմությունը
                </p>
              </div>
            </div>
          )}

          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <SelectControl
                isClearable={false}
                selectBoxTitle="Կազմակերպաիրավական տեսակը"
                options={companyTypesOptions}
                value={{
                  label: values?.companyTypeName,
                  value: values?.companyTypeName,
                }}
                onBlur={handleBlur}
                onChange={(values) => handleChangeCompanyType(values)}
                requiredFiled={true}
              />
              {touched.companyTypeID && errors.companyTypeID && (
                <ErrorFiled error={errors.companyTypeID} />
              )}
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Քաղաք/համայնք"
                name="city"
                value={values?.city}
                changeHandler={handleChange}
                onBlur={handleBlur}
              />
              {touched.city && errors.city && (
                <ErrorFiled error={errors.city} />
              )}
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Հասցե"
                name="address"
                value={values?.address}
                changeHandler={handleChange}
              />
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Գործունեության հասցեն"
                name="activityAddress"
                value={values?.activityAddress}
                changeHandler={handleChange}
              />
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName} `}>
              <InputFiled
                inputTitle="ՀՎՀՀ"
                name="taxCode"
                value={values?.taxCode}
                changeHandler={handleChange}
                requiredFiled={true}
                onBlur={handleBlur}
                maxLength={8}
              />
              {touched.taxCode && errors.taxCode && (
                <ErrorFiled error={errors.taxCode} />
              )}
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Հեռախոս"
                name="phone"
                value={values?.phone}
                changeHandler={handleChange}
              />
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Էլեկտրոնային հասցեն"
                name="email"
                value={values?.email}
                changeHandler={handleChange}
              />
              {touched.email && errors.email && (
                <ErrorFiled error={errors.email} />
              )}
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName} `}>
              <InputFiled
                inputTitle="Ղեկավարի պաշտոնը"
                name="headPosition"
                value={values?.headPosition}
                changeHandler={handleChange}
              />
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Ղեկավարի անուն,ազգանուն"
                name="headName"
                value={values?.headName}
                changeHandler={handleChange}
              />
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Հաշվապահի անուն,ազգանուն"
                name="accauntantName"
                value={values?.accauntantName}
                changeHandler={handleChange}
              />
            </div>
          </div>
          <div className={wrapperClassName}>
            <div className={`${boxesClassName}`}>
              <InputFiled
                inputTitle="Նշումներ"
                name="description"
                value={values?.description}
                changeHandler={handleChange}
              />
            </div>
          </div>
          {currentCompanyID > 0 && (
            <div className="L-reporting-period-wrap">
              <label className="L-reporting-period-title">
                Հաշվետու ժամանակաշրջան
              </label>
              <div className="L-reporting-period-box">
                <p className="G-title-p">Սկիզբ</p>
                <Calendar
                  value={dateFormat(
                    existingDate?.startDate || values?.startDate,
                  )}
                  changeHandler={(date) => onChangeDate(date, 'startDate')}
                  name="startDate"
                  openToDate={new Date(values?.startDate)}
                  removeMinDate={true}
                />
              </div>
              <div className="L-reporting-period-box">
                <p className="G-title-p">Վերջ</p>
                <Calendar
                  value={dateFormat(existingDate?.endDate || values.endDate)}
                  changeHandler={(date) => onChangeDate(date, 'endDate')}
                  name="endDate"
                  openToDate={new Date(values?.endDate)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="L-organization-router-btn">
          {currentCompanyID > 0 && (
            <div className="L-organization-action-btn">
              <div className="G-bank-btn">
                <Button
                  type="button"
                  text="Իմ բանկերը"
                  onClick={openBankForm}
                />
              </div>

              <div className="G-partner-btn">
                <Button
                  type="button"
                  text="Իմ գործընկերները"
                  onClick={openPartnerForm}
                />
              </div>
              <div className="G-init-bal-btn">
                <Button
                  type="button"
                  text="Իմ հաշիվ.սկզբ.մնացորդները"
                  onClick={goToTheInitBalance}
                />
              </div>
            </div>
          )}

          <div className="G-register-btn">
            <Button
              //text={isSubmitting ? <Spinner /> : register}
              text={register}
              customClass={!isValid || !dirty ? 'G-disabled-button' : ''}
              disabled={!isValid || !dirty}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
      {isChangeTaxationSystem && (
        <AcceptOrCancelModal
          closeModal={setIsChangeTaxationSystem}
          confirmClick={confirmChangeTaxationSystem}
          modalContent={`Դուք ընտրել եք հարկման ${saveTaxationSystem.value} տեսակը`}
        />
      )}

      {showHistoryTaxationSystem ? (
        <HistoryTaxationSystem closeModal={setShowHistoryTaxationSystem} />
      ) : null}
    </div>
  );
};

export default OrganizationForm;
