import { getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchSalaryCalculation = async (companyID) => {
  try {
    const response = await getRequest(
      `getCalculationData?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchEmployeesForCalc = async (companyID, depNo = '') => {
  let url = `getEmployeesForCalc?companyID=${companyID}`;
  if (depNo) {
    url += `&depNo=${depNo}`;
  }
  try {
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchExecuteSalaryCalculator = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await postRequest(
      `salaryCalc?companyID=${companyID}&${queryRoot}`,
      [],
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveCalculateData = async (
  currentCompanyID,
  depNo,
  calculateData,
) => {
  try {
    let url = `salaryCalc?companyID=${currentCompanyID}`;
    if (depNo) {
      url += `&depNo=${depNo}`;
    }
    const response = await postRequest(url, calculateData);

    return response.data;
  } catch (error) {
    console.error('Error adding Tables:', error);
    throw error;
  }
};
