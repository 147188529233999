import React, { useEffect, useState } from 'react';
import MakeAnAdditionTable from './MakeAnAdditionTable';
import MakeAnAdditionAction from './MakeAnAdditionAction';
import Header from '../../../App/Components/Header/Header';
import useMakeAnAddition from '../../hooks/useMakeAnAddition';
import './style.scss';
import { filterObjKeys } from '../../../App/Utilities/Utilities';

const MakeAnAddition = () => {
  const {
    getMakeAnAddition,
    makeAnAddition,
    getMakeAnAdditionExecute,
    makeAnAdditionData,
    setMakeAnAdditionData,
  } = useMakeAnAddition();
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  const [query, setQuery] = useState({
    salaryDate: '',
    stNo: '',
    stName: '',
    formulaID: '',
    formulaName: '',
    coeff: '',
    depNo: '',
    depName: '',
  });
  const keysToRemove = ['depName', 'formulaName', 'stName'];
  const modifyObj = filterObjKeys(query, keysToRemove);

  useEffect(() => {
    if (query.stNo && query.formulaID) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [query]);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      salaryDate: makeAnAddition?.salaryDate,
    }));
  }, [makeAnAddition?.salaryDate]);

  useEffect(() => {
    getMakeAnAddition();
  }, []);

  const onExecuteHandler = async () => {
    try {
      await getMakeAnAdditionExecute(modifyObj);
      setIsDisabledBtn(true);
    } catch (err) {
      console.error('An error occurred:', err);
      setIsDisabledBtn(false);
    }
  };

  return (
    <div className="L-make-an-addition-block">
      <Header
        pageTitle="Կատարել հավելում, պահում"
        headerBtn={false}
        showCalculationDate={true}
      />

      <MakeAnAdditionAction
        makeAnAdditionData={makeAnAddition}
        setMakeAnAdditionData={setMakeAnAdditionData}
        query={query}
        setQuery={setQuery}
        onExecuteHandler={onExecuteHandler}
        isDisabledBtn={isDisabledBtn}
      />
      {makeAnAdditionData?.message && (
        <p style={{ marginTop: '20px', textAlign: 'center' }}>
          {makeAnAdditionData?.message}
        </p>
      )}
      <MakeAnAdditionTable mainData={makeAnAdditionData?.makes} />
    </div>
  );
};

export default MakeAnAddition;
