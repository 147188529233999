import React from 'react';
import { exchangeRateTitle } from '../../Utilities/Utilities';
import InputFiled from '../InputFiled/InputFiled';
import './index.scss';
import NumericFormatFiled from '../NumericFormat/NumericFormatFiled';

const ExchangeRateFiled = ({
  value,
  changeHandler,
  customClass,
  disabled,
  currencyCode,
  onFocus,
}) => {
  return (
    <>
      {currencyCode !== 'AMD' && currencyCode !== '' && (
        <div className="G-exchangeRate-input">
          <NumericFormatFiled
            value={value}
            allowNegative={true}
            name="exchangeRate"
            autocomplete="off"
            changeHandler={changeHandler}
            inputTitle={exchangeRateTitle}
          />
        </div>
      )}
    </>
  );
};
export default ExchangeRateFiled;
