import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../Api/Api";
import Header from "../../Components/Header/Header";
import Pagination from "../../Components/Pagination/Pagination";
import SearchBackground from "../../Components/SearchForm/SearchBackground";
import {
  getInvoiceAction,
  loadingAction,
} from "../../Redux/invoiceSlices/useAction";
import { dataTimeFormat, initialDate } from '../../Utilities/Utilities';
import InvoiceAction from './InvoiceAction';
import InvoiceTable from './InvoiceTable';
import './invoice.scss';
import AddInvoiceWrapper from './AddInvoiceWrapper';
import Alert from '../../Components/Alert';

const GetInvoiceData = () => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);
  const { invoiceData, isLoading } = useSelector(
    (state) => state.getInvoiceData,
  );
  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [pageNumber, setPageNumber] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const [partnerInfo, setPartnerInfo] = useState({});
  const [partnerVal, setPartnerVal] = useState({
    label: '',
    value: '',
    id: 0,
  });

  const dispatch = useDispatch();

  const getData = (pageNumber) => {
    dispatch(loadingAction(true));
    getRequest(
      `getInvoices?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&partnerID=${
        partnerVal.id
      }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`,
    )
      .then((res) => {
        dispatch(getInvoiceAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, dispatch, partnerVal.id, dateStartFormat, dateEndFormat]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  return (
    <>
      <Header
        pageTitle="Կանխավճարային/փոխանցման հաշիվ"
        handelClick={openAddForm}
        disabledAddBtn={
          partnerVal.id === 0 || partnerVal.id === '' ? true : false
        }
      />
      <SearchBackground>
        <InvoiceAction
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
          mainData={invoiceData}
          partnerVal={partnerVal}
          setPartnerVal={setPartnerVal}
          setErrorHandler={setErrorHandler}
          setPartnerInfo={setPartnerInfo}
        />
      </SearchBackground>
      <InvoiceTable
        mainData={invoiceData?.invoices}
        loading={isLoading}
        setErrorHandler={setErrorHandler}
      />
      <Pagination
        pageCount={invoiceData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openAddModal && (
        <AddInvoiceWrapper
          closeModal={setOpenAddModal}
          partnerID={partnerVal.id || partnerInfo?.partnerID}
          getData={getData}
          pageNumber={pageNumber}
          setErrorHandler={setErrorHandler}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetInvoiceData;
