import { removeCommas } from '../../../App/Utilities/Utilities';

export const handleChangeHours = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { value } = e.target;
  const newHours = Number(value);

  if (newHours <= 0) return;

  const updatedRows = newMakeAnAddition?.map((r) => {
    if (r.empNo === row.empNo) {
      let newAmount = 0;

      if (r.formulaID === 2 && r.formSign === 2) {
        newAmount = r.salary * newHours * r.coeff;
      } else if (r.workHours > 0) {
        newAmount = (r.salary / r.workHours) * newHours * r.coeff;
      }
      setIsDisabled(false);
      return {
        ...r,
        hours: newHours,
        amount: Math.round(newAmount),
        isModifed: true,
      };
    } else {
      return r;
    }
  });

  setNewMakeAnAddition(updatedRows);
};

export const handleChangeCoefficient = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { value } = e.target;
  const newCoefficient = value && parseFloat(value);

  const updatedRows = newMakeAnAddition?.map((r) => {
    if (r.empNo === row.empNo) {
      let newAmount = 0;

      if (r.formulaID === 2 && r.formSign === 2) {
        newAmount = r.salary * r.hours * newCoefficient;
      } else if (r.workHours > 0) {
        newAmount = (r.salary / r.workHours) * r.hours * newCoefficient;
      }
      setIsDisabled(false);
      return {
        ...r,
        coeff: newCoefficient,
        amount: Math.round(newAmount),
        isModifed: true,
      };
    } else {
      return r;
    }
  });

  setNewMakeAnAddition(updatedRows);
};

export const onAmountChange = (
  e,
  targetRow,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { value } = e.target;
  const newAmount = removeCommas(value);

  const updatedRows = newMakeAnAddition?.map((row) => {
    if (targetRow.empNo === row.empNo) {
      const updatedRow = {
        ...row,
        isModifed: true,
        amount: newAmount,
        sign: true,
      };
      setIsDisabled(false);
      return updatedRow;
    }
    return row;
  });

  setNewMakeAnAddition(updatedRows);
};

export const handleCheckboxChange = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { checked } = e.target;
  setIsDisabled(false);

  const isMarkAll = !row;
  const updatedRows = newMakeAnAddition?.map((item) => {
    if (isMarkAll) {
      if (checked) {
        let amount = 0;

        if (item.formulaID === 1) {
          amount =
            item.formSign === 2
              ? item.salary * item.workHours * item.coeff
              : item.salary * item.coeff;
        } else if (item.formulaID === 2) {
          if (item.formSign === 2) {
            amount = Math.round(item.salary * item.hours * item.coeff, 2);
          } else {
            amount =
              item.workHours > 0
                ? Math.round(
                    (item.salary / item.workHours) * item.hours * item.coeff,
                    2,
                  )
                : 0;
          }
        } else if (item.formulaID === 4) {
          if (item.formSign === 2) {
            amount = Math.round(item.hgAmount * item.hours * item.coeff, 2);
          } else {
            amount =
              item.workHours > 0
                ? Math.round((item.hgAmount / item.workHours) * item.hours, 2)
                : 0;
          }
        } else if (item.formulaID === 3) {
          amount = item.hgAmount * item.coeff;
        }

        return { ...item, sign: true, amount, isModifed: true };
      } else {
        return { ...item, sign: false, isModifed: true, amount: 0 };
      }
    }

    // Handle individual row case
    if (item.empNo === row.empNo) {
      if (checked) {
        let amount = 0;

        if (item.formulaID === 1) {
          amount =
            item.formSign === 2
              ? item.salary * item.workHours * item.coeff
              : item.salary * item.coeff;
        } else if (item.formulaID === 2) {
          if (item.formSign === 2) {
            amount = Math.round(item.salary * item.hours * item.coeff, 2);
          } else {
            amount =
              item.workHours > 0
                ? Math.round(
                    (item.salary / item.workHours) * item.hours * item.coeff,
                    2,
                  )
                : 0;
          }
        } else if (item.formulaID === 4) {
          if (item.formSign === 2) {
            amount = Math.round(item.hgAmount * item.hours * item.coeff, 2);
          } else {
            amount =
              item.workHours > 0
                ? Math.round((item.hgAmount / item.workHours) * item.hours, 2)
                : 0;
          }
        } else if (item.formulaID === 3) {
          amount = item.hgAmount * item.coeff;
        }

        return { ...item, sign: true, amount, isModifed: true };
      } else {
        return { ...item, sign: false, isModifed: true, amount: 0 };
      }
    }

    return item;
  });

  setNewMakeAnAddition(updatedRows);
};
